import React from 'react'
import Callout from 'components/Callout'
import { Link } from 'react-router-dom'

function Ecommerce( ) {
    return (
    <div>
      <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="service_page_container">
                        <div className="row ecommerce_service row justify-content-center">
                        <div className="ecommerce_banner">&nbsp;</div>
                        <div className="col-md-12 ecommerce_solutions">
                            <div className="container">
                            <h1>E-Commerce Website Development Company</h1>
                            <p>Datage is a leading e-commerce Website development company, Hyderabad. We convert your ideas, transform and integrate your online marketplace with your brick and mortar stores, whilst creating an user-friendly and fitting service for your customers</p>
                            <p>We provide length and breadth of e-commerce solutions that includes business to business and business to customer. Our tech savvy team will give you a comprehensive platform layout that will help you grow exponentially</p>
                            <p>Your business model needs a solid enterprise software solution to support your numerous activities, it needs a proportional scaling, aid with other software and mobile apps and cover a broad rundown of different activities. Datage has more than 7 years of experience, technologies, tools and innovative set of minds to comply on any such functional requirements and custom software development.</p>
                            </div>
                        </div>
                        <div className="row ecommerce_service">
                            <div className="container">
                            <h2>Key Benefits of E-Commerce Platform</h2>
                            </div>
                            <div className="col-md-11 m-auto">
                            <div className="row">
                                <div className="col-md-12 ecommerce_service_tools">
                                <div className="row justify-content-center">
                                    <div className="col-md-3 service_tools">
                                    <div className="service_image"><span><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/e-revenue.png" /></span></div>
                                    <span>Better Revenue</span>
                                    <p>We use creative strategies to increase your revenue such as abandoned cart offers, Cross and selling offers etc.</p>
                                    </div>
                                    <div className="col-md-3 service_tools">
                                    <div className="service_image"><span><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/e-customizable.png" /></span></div>
                                    <span>Highly Customizable </span>
                                    <p>Highly Customizable application provides you and your business to make your presence in online and increase your revenue.</p>
                                    </div>
                                    <div className="col-md-3 service_tools">
                                    <div className="service_image"><span><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/e-globalreach.png" /></span></div>
                                    <span>Global Reach</span>
                                    <p>We have set of tools to Expand global market that will help to exceed your number of consumers to fulfill a transaction.</p>
                                    </div>
                                    <div className="col-md-3 service_tools">
                                    <div className="service_image"><span><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/e-effective.png" /></span></div>
                                    <span>Cost Effective</span>
                                    <p>We are one of the best in e-commerce platform, using our advance Django Oscar and AWS for hosting and file storage.</p>
                                    </div>
                                    <div className="col-md-3 service_tools">
                                    <div className="service_image"><span><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/e-payment.png" /></span></div>
                                    <span>Multiple Payment Modes</span>
                                    <p>Multiple payment options like Online Credit card transactions, Cash on delivery.</p>
                                    </div>
                                    <div className="col-md-3 service_tools">
                                    <div className="service_image"><span><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/e-scalable.png" /></span></div>
                                    <span>Scalable</span>
                                    <p>Offers scalable e-commerce solutions which are flexible enough to meet your needs of future expansion</p>
                                    </div>
                                    <div className="col-md-3 service_tools">
                                    <div className="service_image"><span><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/e-secure.png" /></span></div>
                                    <span>Secure</span>
                                    <p>e-Commerce delivers secure websites to protect your valuable data and provide secure payment transactions</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="container"><span>Our E-Commerce Website Development Services Includes</span></div>
                        <p>Our E-commerce Website Development Services Includes <a href="https://datage.in/responsive-web-design/">Responsive Web design</a>, Payment Gateway Integration, <a href="https://datage.in/django-development-services/">Website Development</a>, <a href="https://datage.in/react-native-development-services/"> Mobile Application Development</a>, <a href="https://datage.in/digital-marketing-services/">Digital Marketing</a>, <a href="https://datage.in/website-maintenance-services/">Website Maintenance and Support</a></p>
                        <div>
                            <div className="row">
                            <div className="col-md-12 ecommerce_tools">
                                <div className="row">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item active"><a aria-controls="pills-profile" aria-selected="false" className="nav-link" data-toggle="pill" href="#pills-profile" id="pills-profile-tab" role="tab">Responsive Web Design</a></li>
                                    <li className="nav-item"><a aria-controls="pills-contact" aria-selected="false" className="nav-link" data-toggle="pill" href="#pills-contact" id="pills-contact-tab" role="tab">Payment Gateway Integration</a></li>
                                    <li className="nav-item"><a aria-controls="pills-profile" aria-selected="false" className="nav-link" data-toggle="pill" href="#pills-profiles" id="pills-profile-tab" role="tab">website Development</a></li>
                                    <li className="nav-item"><a aria-controls="pills-profile" aria-selected="false" className="nav-link" data-toggle="pill" href="#pills-mobile" id="pills-profile-tab" role="tab">Mobile Application Development</a></li>
                                    <li className="nav-item"><a aria-controls="pills-contact" aria-selected="false" className="nav-link" data-toggle="pill" href="#pills-contacts" id="pills-contact-tab" role="tab">Digital Marketing</a></li>
                                    <li className="nav-item border-right"><a aria-controls="pills-home" aria-selected="false" className="nav-link" data-toggle="pill" href="#pills-home" id="pills-home-tab" role="tab">Maintenance &amp; Support</a></li>
                                </ul>
                                <div className="container">
                                    <div className="tab-content" id="pills-tabContent">
                                    <div aria-labelledby="pills-home-tab" className="tab-pane fade" id="pills-profile" role="tabpanel">
                                        <div className="row cart_development">
                                        <div className="col-md-7">
                                            <h3>Responsive Web Design</h3>
                                            <p>An optimal rich user experience web design and user Interface for mobile, tablet and desktop that will increase web traffic which in turn will increase your online revenue with global presence.</p>
                                        </div>
                                        <div className="col-md-5 ecommerce-service-images"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/ecommerce_web_responsive.png" /></div>
                                        </div>
                                    </div>
                                    <div aria-labelledby="pills-contact-tab" className="tab-pane fade" id="pills-contacts" role="tabpanel">
                                        <div className="row cart_development">
                                        <div className="col-md-7">
                                            <h3>Digital Marketing</h3>
                                            <p>We invest our time in implementing and measuring ROI on strategies like optimising your ecommerce marketing tactics lists that we’ve rounded up to make sure your business is a stand out from the crowd.</p>
                                        </div>
                                        <div className="col-md-5 ecommerce-service-images"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/ecoomerce_cart.png" /></div>
                                        </div>
                                    </div>
                                    <div aria-labelledby="pills-profile-tab" className="tab-pane fade" id="pills-profiles" role="tabpanel">
                                        <div className="row cart_development">
                                        <div className="col-md-7">
                                            <h3>Website development</h3>
                                            <p>Our Web Development Team develops and delivers the projects with high user engagement. We provide flexible features to bring the most satisfactory outcomes for your online business.</p>
                                        </div>
                                        <div className="col-md-5 ecommerce-service-images"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/ecommerce-development.png" /></div>
                                        </div>
                                    </div>
                                    <div aria-labelledby="pills-profile-tab" className="tab-pane fade" id="pills-mobile" role="tabpanel">
                                        <div className="row cart_development">
                                        <div className="col-md-7">
                                            <h3>Mobile Application Development</h3>
                                            <p>We believe in functional, aesthetic and high standard customer engagement specialising in developing enterprise and consumer mobile application solution in iOS and Android.</p>
                                        </div>
                                        <div className="col-md-5 ecommerce-images"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/ecommerce_mobile.png" /></div>
                                        </div>
                                    </div>
                                    <div aria-labelledby="pills-profile-tab" className="tab-pane fade" id="pills-home" role="tabpanel">
                                        <div className="row cart_development">
                                        <div className="col-md-7">
                                            <h3>Website Maintenance and Support</h3>
                                            <p>We have, on request, 24/7 support available for your website right from the instant it goes live. Your success in business is our success and we ensure your website will run smoothly with original and relevant content.</p>
                                        </div>
                                        <div className="col-md-5 ecommerce-service-images"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/ecommerce_support_maintainence.png" /></div>
                                        </div>
                                    </div>
                                    <div aria-labelledby="pills-contact-tab" className="tab-pane fade" id="pills-contact" role="tabpanel">
                                        <div className="row cart_development">
                                        <div className="col-md-7">
                                            <h3>Payment Gateway Integration</h3>
                                            <p>We follow best practices for payment gateway integration in both ways, hosted and integrated, all of which are secure and have live support.</p>
                                        </div>
                                        <div className="col-md-5 ecommerce-images"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/ecommerce_gateway.png" /></div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="ecommerce-hire">
                            <div className="hire_developer">
                                <div className="container"><span>Hire E-commerce Developers</span>
                                <p>Hire dedicated eCommerce developers at Datage who has experience in developing e-commerce websites in various industries using the latest eCommerce development tools. Our e-commerce Developers provide solutions to your small, medium and big level business and get updated technically according to the latest market trend.</p>
                                <Link to="/contact-us/">Request a Free Quote Now</Link></div>
                            </div>
                            </div>
                        </div>
                        
                        <div className="container">
                            <div className="col-md-12 mp-services"><span>Related Services</span>
                            <div className="row">
                                <div className="col-md-4 black"><a href="https://datage.in/django-development-services/">Django Development</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/python-development-services/">Python Development</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/reactjs-development-services/">ReactJs Development</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/angularjs-development-services/">AngularJS Development</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/e-learning-software-development-services/">E-Learning Software Development</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/react-native-development-services/">React Native Development</a></div>
                            </div>
                            </div>
                        </div>
                        <div className="container">
                            
                            &nbsp;
                            <div className="contact_container row justify-content-center">
                            <div className="col-md-12 col-lg-12">
                                <div className="contact_block row marl">
                                <div className="col-md-7 col-xs-12">
                                <Callout />
                                </div>
                                <div className="col-md-5 col-xs-12 col-sm-12">
                                    <div className="contact_content"><span>E-commerce Development</span>
                                    <p>E-commerce makes easy to develop web and mobile applications. With angular, we can create Single page applications and website menu in an instant. Get experienced angular developer when you need.</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/*/ container wrapper */}</div>
                    <div className="container">
                    </div>
                    </div>
                </div>
                <div className="col-md-12 customer_reviews">
                    <div className="container">
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Ecommerce
