import React from 'react'
import { Link } from 'react-router-dom';

function TechSection( ) {
    const stack = [
        {title: '', img: '', lnk: ''},
    ];
    return (
        <div className="mt-5">
            <section className="web_development web_technologies">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-md-12 col-sm-10 col-xs-12 text-center">
                        <h2 className="heading text-center">Our Tech Stack
                        </h2>
                        <div className="section_logos" style={{opacity:"0.8"}}>
                        <ul>
                            <li><Link to="/reactjs-development-services/"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/react.png" alt /></Link></li>
                        
                            <li><Link to="/python-development-services/"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/python_logo.png" alt /></Link></li>
                            <li><Link to="/django-development-services/"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/django_no-color.png" alt /></Link></li>
                            <li><Link to="/aws-consulting-partner/"><img loading="lazy" alt="Standard Consulting Partner" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/aws_home.png" /></Link></li>
                            <li><Link to="/#"><img loading="lazy" alt="React" src="/assets/img/new-images/graphql.png" /></Link></li>
                            <li><Link to="/angularjs-development-services/"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/angular_red.png" alt /></Link></li>
                            <li><Link to="/django-rest-framework-development-services/"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/django_rest.png" alt /></Link></li>
                            
                        </ul>
                        <div className="section_logos second_section pt-4">
                            <ul>
                            <li><Link to="/#"><img loading="lazy" alt="Django" src="/assets/img/new-images/nodejs.png" width={60} /></Link></li>
                            <li><Link to="/bootstrap-web-development/"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/bootstrap.png" alt /></Link></li>
                            <li><a target="_blank" title="Laravel"><img loading="lazy" alt="Flask" src="/assets/img/new-images/laravel.png" style={{maxWidth: 50}} /></a></li>
                            <li><Link to="/docker-consulting/">
                                <img loading="lazy" alt="Docker" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/docker_logo_home.png" /></Link>
                            </li>
                            <li><a target="_blank"><img loading="lazy" alt="Django" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/gcp_logo_home.png" width="85%" /></a></li>
                            <li><Link to="/react-native-development-services/"><img loading="lazy" alt="Ansible" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/ios_color-min.png" /></Link></li>
                            <li><Link to="/mongodb-development-services/"><img loading="lazy" alt="Django" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/mongo_menu.jpg" style={{maxWidth: 60}} /></Link></li>
                            </ul>
                        </div>
                        </div>
                            <div class="text-center">
                            <Link to="/our-tech-stack/"><strong>View All</strong></Link>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
        </div>
    )
}

export default TechSection;
