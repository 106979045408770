import React from 'react'
import Callout from 'components/Callout'

function AmpDesign( ) {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="seo_banner" style={{width: '100%'}}>
                        <div className="container">
                        <div className="service_banner row no-gutters justify-content-center">
                            <div className="col-lg-12">
                            <h1 className="heading">AMP Web Design and Development Services</h1>
                            <div className="content">
                                <p>The Accelerated Mobile Pages(AMP) is an effort by Google to offer an accessible framework for creating fast loading mobile web pages. You might have observed that in mobile devices static content pages take an awful lot of time to load. Even after the text becomes visible the pages continue to load as images &amp; ads come into display. This results in a very unpleasant experience for the readers as the position of the text keeps on shifting. This is the main reason which led to the improvement of the entire mobile content ecosystem for the benefit of publishers, creators &amp; users as a whole using AMP.</p>
                                <p>AMP enables publishers to easily improve the loading speed of their pages on a mobile without compromising on the ad revenues. Apart from this you also have the AMP Ad landing pages (ALP) which are designed to speed up the experience of a user who clicks on an AMP ad. This is done by pre-loading the URL of the landing page in the ad and by forcing the landing page to use the AMP HTML protocol.</p>
                                <p>The main goal of the Accelerated Mobile Pages is to see that effective ad monetization on the mobile web takes place while it follows a user-centric approach. Not just this but it also has the objective to provide support for a comprehensive range of ad formats, ad networks and technologies in AMP. So if you also own a mobile site and want us to implement AMP HTML to your current HTML pages in order to increase their loading speed without compromising on your ad revenue then contact us today. Micro Pyramid’s expert team can help you with the Accelerated Mobile Page design and development of your website.</p>
                                <h2 className="heading">Components of AMP</h2>
                                <div className="design_frame_work">
                                <p className="text-center d-block">AMP which is basically a framework for creating mobile web pages consists of the following parts –</p>
                                <p><strong>AMP HTML</strong> This is a markup language and is you know HTML quite well then you can easily adapt the existing pages to AMP HTML.</p>
                                <p><strong>AMP JS </strong> This is a JavaScript framework for mobile pages. It basically manages resource handling and asynchronous loading.</p>
                                <p><strong>AMP CDN –</strong> This is an optional content delivery network which takes your AMP-enabled pages, cache them and automatically make some performance optimizations.</p>
                                <p className="d-block">If you want to know more about AMP Web design and development then write to us at <a href="mailto:hello@datage.in">hello@datage.in</a> and we shall get back to you with an answer to your queries.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-12 mp-services"><span>Related Services</span>
                        <div className="container">
                            <div className="row">
                            <div className="col-md-4 black"><a href="https://datage.in/bootstrap-web-development/">Bootstrap Web Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/responsive-web-design/">Responsive Web Design Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/python-development-services/">Python Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/aws-consulting-services/">AWS Consulting Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website Maintenance Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/react-native-development-services/">React Native Development</a></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        
                        <div className="contact_container row justify-content-center">
                        <div className="col-md-12 col-lg-12">
                            <div className="contact_block row marl">
                            <div className="col-md-7 col-xs-12">
                            <Callout />
                            </div>
                            <div className="col-md-5 col-xs-12 col-sm-12">
                                <div className="contact_content"><span className="jost">AMP Web Design Services</span>
                                <p>AMP can drastically benefit the performance of your mobile site. It is quite instrumental in enhancing your mobile SEO and brings in organic traffic to your website taking into consideration that Google has implemented it in its search engine.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    h2
                    <div className="container">
                    </div>
                    </div>
                </div>
                <div className="col-md-12 customer_reviews">
                    <div className="container">
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default AmpDesign;
