import React from 'react'
import Callout from 'components/Callout'

function Learning( ) {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="row about_elearning justify-content-center">
                        <div className="e-learning_process">
                        <div className="container"><span> E-learning Software<br />
                            Development Services </span></div>
                        </div>
                        <div className="col-md-12 e-learning_feactures">
                        <div className="container">
                            <h1 className="heading">E-learning Software Development Company</h1>
                            <p>Datage is the Leading E-learning Software Development company and provide best offshore e-learning development services in Hyderabad, India.</p>
                            <p>E-learning, the learning conducted via electronic media is gaining wide popularity in today’s time and is ideal if your organization wants to target students for a distance learning course. Due to a busy lifestyle people prefer to take online classes for a particular subject, from the comfort of their home rather than go physically to a place to learn about the subject.</p>
                            <p>We at Datage provide ease to the users in utilizing electronic media to gain knowledge about a particular subject outside of a traditional classroom.</p>
                        </div>
                        </div>
                        <div className="row e-learning_development">
                        <div className="container">
                            <h2 className="heading">Why To Outsource E-learning Software Development Services From Datage?</h2>
                            <p>Datage offers e-learning software development services to it's clients and forms a distinguished client base over the years.&nbsp;Here are some key reasons to outsource e-learning software development services from Datage.</p>
                            <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                <div className="col-md-6 col-lg-4">
                                    <div className="elearning_expert">
                                    <div><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/react_expert1.png" /></div>
                                    <div className="elearning_expertise">
                                        <h2 className="elearning_images_text">Expertise In E-learning Development</h2>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="elearning_expert">
                                    <div><img alt="On Time Project Delivery " src="https://d28k6hocvoxiuc.cloudfront.net/site/images/project-delivery.png" /></div>
                                    <div className="elearning_expertise">
                                        <h2 className="elearning_images_text">On Time Project Delivery</h2>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="elearning_expert">
                                    <div><img alt="Immediate Support and Maintenance" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/chat-support.png" /></div>
                                    <div className="elearning_expertise">
                                        <h2 className="elearning_images_text">Immediate Support and Maintenance</h2>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="elearning_expert">
                                    <div><img alt="Quality Service" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/management.png" /></div>
                                    <div className="elearning_expertise">
                                        <h2 className="elearning_images_text">Quality Services</h2>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="elearning_expert">
                                    <div><img alt="client Satisfaction" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/rating.png" /></div>
                                    <div className="elearning_expertise">
                                        <h2 className="elearning_images_text">100% Client Satisfaction</h2>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="elearning_expert">
                                    <div><img alt="Hire dedicated Developers" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/human-resources.png" /></div>
                                    <div className="elearning_expertise">
                                        <h2 className="elearning_images_text">Hire Dedicated Developers</h2>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="e-learning_tools_quality row">
                        <div className="container"><span className="heading">Our Process</span>
                            <p>Datage IT services provide global organizations competitive edge by leveraging the right mix of technology, people, and processes. Application Development process starts with planing, designing, developing, testing and delivering.</p>
                            <div className="process_image"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/e-learning_process.png" /></div>
                        </div>
                        </div>
                        <div className="row our_elearning_service">
                        <div className="container"><span>Our E-learning Development Service Includes</span></div>
                        <div className="container">
                            <div className="row elearning_work">
                            <div className="col-md-12">
                                <div className="row">
                                <div className="col-md-5">
                                    <p className="text-right">Our E-Learning software development services encompasses online exams, scheduling, training, CMS, code practice, statistics and customized reports etc.</p>
                                </div>
                                <div className="col-md-7 mobile_rs"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/elearning_process12.png" /></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        
                    </div>
                    <div className="col-md-12 mp-services"><span className="heading">Related Services</span>
                        <div className="container">
                        <div className="row">
                            <div className="col-md-4 black"><a href="https://datage.in/python-development-services/">Python Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/reactjs-development-services/">ReactJS Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/django-development-services/">Django Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/django-oscar-development-services/">Django-oscar E-commerce Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/angularjs-development-services/">AngularJS Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website Maintenance Services</a></div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        
                        <p>&nbsp;</p>
                        <div className="contact_container row justify-content-center">
                        <div className="col-md-12 col-lg-12">
                            <div className="contact_block row marl">
                            <div className="col-md-7 col-xs-12">
                            <Callout />
                            </div>
                            <div className="col-md-5 col-xs-12 col-sm-12">
                                <div className="contact_content"><span className="heading">E-learning Software Development Services</span>
                                <p>We are one of the earliest adopters and expeditious in React Native, our team is fixed over delivering quality and flexible at customization. Our IT experts with wealth of resources respond effectively to your business demands. For global support and quick assistance we are always-on, businesses run 24 x 7.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                    </div>
                    </div>
                </div>
                <div className="col-md-12 customer_reviews">
                    <div className="container">
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Learning
