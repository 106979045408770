import React from 'react';
import FooterContact from './FooterContact';
import FooterLinks from './FooterLinks';

function Footer( ) {
    return (
        <div>
        <footer className="cookie-policy_accept pb-3 mt-5" style={{paddingBottom: '0px !important', background: "#fcfcfc"}}>
        <div className="row footer_bar marl" style={{background: "#fcfcfc"}}>
            <div className="container">
                 <FooterContact />
                 <FooterLinks />
                 <div className="border-top pt-3 mt-3"><small>© 2021, Datage Technologies.</small></div>
            </div>
           
        </div>
    </footer>
</div>
    )
}

export default Footer
