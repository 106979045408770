import React from "react";

export default function Callout() {
	return (
			<div className="contact_details mt-3">
				<div className="detail " style={{display: "none"}}>
					<i className="fa fa-phone" />
					<a href="tel:+">91-</a>
				</div>
				<div className="detail">
					<i className="fa fa-envelope" />{" "}
					<a href="mailto:hello@datage.in">hello@datage.in</a>
				</div>
				<div className="detail">
					<i className="fa fa-map-marker" />
					#22, Salarpuria-I, Koramangala, Bengaluru{" "}
				</div>
				<a href="/contact-us/">Contact Us</a>
			</div>
	);
}
