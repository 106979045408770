import React from 'react'
import Callout from 'components/Callout'

function DjangoRest( ) {
    return (
     <div>
       <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="seo_banner">{/* service_banner starts here */}
                        <div className="service_banner row no-gutters justify-content-center">
                        <div className="col-lg-8 col-md-8">
                            <h1 className="heading">Web Browsable API With Django Rest Framework</h1>
                            <div className="content">
                            <p>Django Rest Framework is a tool Datage utilizes to provide RestFul APIs to our clients. We capable of building complex web API's which provides easier way to perform database operations. We also able to build swagger UI Web API documentation to view all endpoints.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="service_qualities testing_service_quality mongo_service_quality row no-gutters">
                        <div className="col-md-12">
                        <div className="row justify-content-center text-center">
                            <h3 className="heading">Why do we Choose REST Framework?</h3>
                        </div>
                        {/* service_quality starts here */}
                        <div className="row">
                            <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="service_quality">
                                    <div className="content">
                                    <div className="icon"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/quality_testing.png" /></div>
                                    <strong>Test Coverage</strong>
                                    <p>Django Rest Framework provides ability to write test cases to run either in development or production. We can ensure that, tests do not directly effect on customer data.</p>
                                    </div>
                                </div>
                                </div>
                                {/* service_quality ends here */}{/* service_quality starts here */}
                                <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="service_quality">
                                    <div className="content">
                                    <div className="icon"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/recycle-sign.png" /></div>
                                    <strong>Flexibility</strong>
                                    <p>REST framework provides flexible per-request authentication, that gives you the ability to use different authentication policies for different parts of your API.</p>
                                    </div>
                                </div>
                                </div>
                                {/* service_quality ends here */}{/* service_quality starts here */}
                                <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="service_quality">
                                    <div className="content">
                                    <div className="icon"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/progress-report.png" /></div>
                                    <strong>Quality</strong>
                                    <p>High-performance web APIs and quality design, to support Web and mobile applications and also maintaining and debugging applications is easier.</p>
                                    </div>
                                </div>
                                </div>
                                {/* service_quality ends here */}{/* service_quality starts here */}
                                <div className="col-md-6 col-lg-6 col-sm-6">
                                <div className="service_quality">
                                    <div className="content">
                                    <div className="icon"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/Flexibility-Icon-150x150.png" /></div>
                                    <strong>Simplicity</strong>
                                    <p>REST API is designed to reduce complexity by separating each unit of functionality, So we can easily understand. Each service has its own unique capabilities that are made accessible through an interface.</p>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-sm-6">
                                <div className="service_quality">
                                    <div className="content">
                                    <div className="icon"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/hierarchical-structure.png" /></div>
                                    <strong>serialization</strong>
                                    <p>The most powerful feature of the Django Rest Framework is having built in model serialization. With just a few lines of code we can compose powerful representations of data that can be delivered in a number of formats which can compatible with orm and non-orm data sources.</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="how_we_do_container mongo_business_col row marl">
                        <div className="container">
                        <h2 className="heading text-center">We Are Proud To Be One Of The Sponsors For Django REST Framework.</h2>
                        </div>
                    </div>
                    
                    <div className="col-md-12 mp-services"><span className="heading">Related Services</span>
                        <div className="container">
                        <div className="row">
                            <div className="col-md-4 black"><a href="https://datage.in/python-development-services/">Python Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/reactjs-development-services/">ReactJS Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/angularjs-development-services/">AngularJS Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/django-oscar-development-services/">Django Oscar E-commerce Development</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/e-learning-software-development-services/">E-learning Software Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website Maintenance Services</a></div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                       
                        <div className="contact_container row justify-content-center">
                        <div className="col-md-12">
                            <div className="contact_block row marl">
                            <div className="col-md-7">
                            <Callout />
                            </div>
                            <div className="col-md-5 col-xs-12 col-sm-12">
                                <div className="contact_content"><span className="jost">Django REST Framework Development Services</span>
                                <p>We Datage follows to deliver Quality applications to our customers. We also allows the flexibility to extend and customize the framework’s tools that greatly reduces development time.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
                <div className="col-md-12 customer_reviews">
                <div className="container">
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default DjangoRest
