import React from 'react'
import Callout from 'components/Callout'

function MongoDb( ) {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="seo_banner">{/* service_banner starts here */}
                        <div className="service_banner row no-gutters justify-content-center">
                        <div className="col-lg-8 col-md-8">
                            <h1 className="heading">Develop a high-performance web application with MongoDB</h1>
                            <div className="content">
                            <p>MongoDB is an excellent opportunity to take advantage of the explosive demand for database development, consulting and managed services. We have experienced MongoDB developers in building effective applications, handling massive data with MongoDB. We design, develop the application the way you want, we also offer strategy and guidance in its optimal use for your situation.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="service_qualities testing_service_quality mongo_service_quality row no-gutters">
                        <div className="col-md-12">
                        <div className="row text-center">
                            <h2 className="text-center heading hundred_percentage">Why To Choose Us For MongoDB Development Services?</h2>
                        </div>
                        {/* service_quality starts here */}
                        <div className="row">
                            <div className="col-md-12">
                            <div className="container">
                                <div className="row">
                                <div className="col-lg-4 col-sm-6 col-xs-12">
                                    <div className="service_quality">
                                    <div className="content">
                                        <div className="icon"><img alt="Uses Bson Format" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/json-file.png" /></div>
                                        <strong className="jost">Uses Bson Format</strong>
                                        <p>BSON is a JSON-like storage format that MongoDB uses when storing documents in collections(tables). It supports all types of data, also large binary objects.</p>
                                    </div>
                                    </div>
                                </div>
                                {/* service_quality ends here */}{/* service_quality starts here */}
                                <div className="col-lg-4 col-sm-6 col-xs-12">
                                    <div className="service_quality">
                                    <div className="content">
                                        <div className="icon"><img alt="Indexing" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/index-folder.png" /></div>
                                        <strong className="jost">Indexing</strong>
                                        <p>Any field in a MongoDB document can be indexed. We can easily perform complex queries by making indexes on JSON data, can easily generate reports by gathering all types of data.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-xs-12">
                                    <div className="service_quality">
                                    <div className="content">
                                        <div className="icon"><img alt="MongoDB Mangement Services" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/mms.png" /></div>
                                        <strong style={{fontSize: '1.1rem'}}>MongoDB Management Service</strong>
                                        <p>A powerful web tool that allows us tracking our databases and our machines and also backing up our data. It provides custom alerts which helps to discover issues before your MongoDB instance will be affected.</p>
                                    </div>
                                    </div>
                                </div>
                                {/* service_quality ends here */}{/* service_quality starts here */}
                                <div className="col-lg-4 col-sm-6 col-xs-12">
                                    <div className="service_quality">
                                    <div className="content">
                                        <div className="icon"><img alt="Ad Hoc Queries" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/analytics.png" /></div>
                                        <strong className="jost">Ad Hoc Queries</strong>
                                        <p>We can do a field level queries, range level queries, expression related queries. It also supports predefined functions to get the results from massive data.</p>
                                    </div>
                                    </div>
                                </div>
                                {/* service_quality ends here */}{/* service_quality starts here */}
                                <div className="col-lg-4 col-sm-6 col-xs-12">
                                    <div className="service_quality">
                                    <div className="content">
                                        <div className="icon"><img alt="Scalability" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/progress-report.png" /></div>
                                        <strong className="jost">Scalability</strong>
                                        <p>We can scale your database schema efficiently and inexpensively which increases the performance of data processing.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-xs-12">
                                    <div className="service_quality">
                                    <div className="content">
                                        <div className="icon"><img alt="Sharding" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/hierarchical-structure.png" /></div>
                                        <strong className="jost">Sharding</strong>
                                        <p>We can deploy your application data across multiple data centers as the application grows rapidly. With load balancing, we can easily manage your application with massive data.</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        {/* service_quality ends here */}{/* service_quality starts here */}{/* service_quality ends here */}{/* service_quality starts here */}{/* service_quality ends here */}</div>
                    </div>
                    <div className="how_we_do_container mongo_business_col row marl">
                        <div className="container"><span className="heading text-center">How we do</span>
                        <p>Datage Informatics Pvt Ltd offers MongoDB consulting Services and provides a wide spectrum of MongoDB development services to its clients. We’ll provide 24*7 support to provide high performance, excellence towards the work.</p>
                        <div className="row how_we_do_col justify-content-center">{/* we_do_block starts here */}
                            <div className="col-md-4 col-sm-4 col-lg-2 each_row">
                            <div className="we_do_block">
                                <div className="icon"><i className="fa fa-paint-brush" /></div>
                                <strong className="jost">Design</strong></div>
                            </div>
                            {/* we_do_block ends here */}{/* we_do_block starts here */}
                            <div className="col-md-4 col-sm-4 col-lg-2 each_row">
                            <div className="we_do_block">
                                <div className="icon"><i className="fa fa-cogs" /></div>
                                <strong className="jost">Development</strong></div>
                            </div>
                            {/* we_do_block ends here */}{/* we_do_block starts here */}
                            <div className="col-md-4 col-sm-4 col-lg-2 each_row">
                            <div className="we_do_block">
                                <div className="icon"><i className="fa fa-shield" /></div>
                                <strong className="jost">Confidentiality &amp; Security</strong></div>
                            </div>
                            {/* we_do_block ends here */}{/* we_do_block starts here */}
                            <div className="col-md-4 col-sm-4 col-lg-2 each_row">
                            <div className="we_do_block">
                                <div className="icon"><i aria-hidden="true" className="fa fa-comments" /></div>
                                <strong className="jost">Seamless Communication</strong></div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-lg-2 each_row">
                            <div className="we_do_block">
                                <div className="icon"><i aria-hidden="true" className="fa fa-question-circle" /></div>
                                <strong className="jost">24*7 Support</strong></div>
                            </div>
                            {/* we_do_block ends here */}</div>
                        </div>
                    </div>
                    
                    <div className="col-md-12 mp-services"><span className="heading">Related Services</span>
                        <div className="container">
                        <div className="row">
                            <div className="col-md-4 black"><a href="https://datage.in/postgresql-administration-and-management/">Postgresql Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/django-rest-framework-development-services/">Django REST Framework Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/django-development-services/">Django Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/python-development-services/">Python Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/aws-consulting-services/">AWS Consulting Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website Maintenance Services</a></div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        
                        <div className="contact_container row justify-content-center">
                        <div className="col-md-12 col-sm-12 col-lg-12">
                            <div className="contact_block row marl">
                            <div className="col-md-7 col-xs-12">
                            <Callout />
                            </div>
                            <div className="col-md-5 col-xs-12 col-sm-12">
                                <div className="contact_content"><span className="jost">MongoDB Development Services</span>
                                <p>MongoDB Consulting Services by Datage follow a dynamic approach and make sure that we deliver top-notch MongoDB applications to our customers. With MongoDB, we can deliver applications with massive data in minimum time and minimum effort, can scale easily our database with no downtime as the application grows rapidly.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
                <div className="col-md-12 customer_reviews">
                <div className="container">
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default MongoDb
