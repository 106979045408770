import React from 'react'
import Callout from 'components/Callout'

function SimpleNotification( ) {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="seo_banner">{/* service_banner starts here */}
                        <div className="service_banner row no-gutters justify-content-center">
                        <div className="col-lg-8 col-md-8">
                            <h1 className="heading">Amazon Simple Email Services</h1>
                            <div className="content">
                            <p><strong>Amazon SES</strong> stands for Amazon simple email service. As the name suggests it is a simple, reliable and cost-effective email platform. It is built on a very reliable and scalable infrastructure it was developed by Amazon.com to serve its own customer base.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="service_qualities why_to_choose row" style={{paddingTop: 10, paddingBottom: 10, marginTop: 15}}>
                        <div className="container">
                        <div className="desc">
                            <div className="row text-center">
                            <h2 className="heading hundred_percentage">You can use Amazon SES for the following</h2>
                            </div>
                            <div className="row">
                            <div className="col-md-12">
                                <p><strong>Marketing Email -</strong> Amazon S3 is extremely simple to use. It comes with a web-based management console and a mobile app. You can also integrate it with third-party technologies as Amazon S3 provides you with full REST APIs and SDKs.</p>
                                <p><strong>Transaction Email -</strong> With the help of Amazon SES you can send automated emails to your consumers at a very low price. These emails include order confirmations, shipping notices, order status updates, password reset emails and other such mails that can be of great value to your consumer.</p>
                                <p><strong>Notifications -</strong> Any event that requires reporting, workflow status updates, reports and application error alerts can be sent out with the help of Amazon SES.</p>
                                <p><strong>Receiving emails -</strong> Amazon SES is not just used to send emails but also to receive them. You can use Amazon SES to receive messages and deliver them to an Amazon S3 bucket. You can call custom code via an AWS lambda function or publish notifications to Amazon SNS.</p>
                                <p>Let us further discuss the many benefits of Amazon SES</p>
                                <p><strong>Reliable -</strong> Amazon SES runs with the help of Amazon’s cloud network infrastructure and data centers. This provision of multiple servers and data centers provides high availability and data durability.</p>
                                <p><strong>Scalable -</strong> Another great feature of Amazon SES is that it is scalable. It is based on the scalable cloud-based email technology, which is used by Amazon websites around the world to send billions of messages in a year.</p>
                                <p><strong>Inexpensive -</strong> Another important feature of Amazon SES is that it is not expensive. In other words, there are no up-front fees or fixed expenses with Amazon SES. You are required to pay very low charges for the number of emails sent and the number of emails received. Other than that, the data transfer fee is also very less.</p>
                                <p><strong>Optimal inbox placement -</strong> One very unique feature of Amazon SES is optimal inbox placement. Amazon SES takes steps to maximize the percentage of your emails that arrive in the inbox of your recipients.</p>
                                <p>The service of Amazon SES is being used by many well-known companies worldwide to make their email marketing campaigns reliable and cost-effective. A few of its featured customers include the following –</p>
                                <ul className="bullet_ul">
                                <li>Ticketmaster</li>
                                <li>Siemens</li>
                                <li>Vodafone</li>
                                <li>Pixnet</li>
                                <li>Fliptop</li>
                                <li>D-Link</li>
                                <li>Code</li>
                                <li>HTC and many more</li>
                                </ul>
                                <p>Contact us at <a href="mailto:hello@datage.in">hello@datage.in</a> if you would like to know more about Amazon SES or if you would like to start using its efficient and beneficial services.</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-12 mp-services"><span>Related Services</span>
                        <div className="container">
                        <div className="row">
                            <div className="col-md-4 black"><a href="https://datage.in/aws-consulting-services/">AWS Consulting Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/amazon-simple-storage-services/">Amazon S3 Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/aws-consulting-partner/">AWS Consulting Partner</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/server-maintenance-services/">Server Maintenance Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/docker-consulting/">Docker Consulting Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website Maintenance Services</a></div>
                        </div>
                        </div>
                    </div>
                    
                    <div className="contact_container row justify-content-center">
                        <div className="col-md-12 col-lg-10">
                        <div className="contact_block row marl">
                            <div className="col-md-7 col-xs-12">
                            <Callout />
                            </div>
                            <div className="col-md-5 col-xs-12 col-sm-12">
                            <div className="contact_content"><span>Amazon Simple Email Services</span>
                                <p>With the help of Amazon SES, you can send and receive mails easily without any minimum commitments required. This means that you pay as you go. Also, you only pay for what you use. You can also use it for bulk email service.</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
                <div className="col-md-12 customer_reviews">
                <div className="container">
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default SimpleNotification
