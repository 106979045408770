import React from 'react'
import Callout from 'components/Callout'

function ReactNative() {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="row react_about_row justify-content-center" style={{marginTop: 0}}>
                        <div className="react_process"><img alt="react native development company" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/react_native_dev1.png" /></div>
                        <div className="col-md-12 react_feactures">
                        <div className="container">
                            <h1 className="heading">React Native Development Company</h1>
                            <p>Datage is the leading React Native Development Company and provide best offshore development services.</p>
                            <p>Build cross-platform applications for IOS and Android with efficiency and Speed with the help of React Native Development Company. React Native is a Javascript framework composes rich mobile UI from Declarative components. See, why our Developers prefer React Native.</p>
                            <div className="row">
                            <div className="col-md-1">&nbsp;</div>
                            <div className="col-md-5">
                                <ul className="bullet_ul">
                                <li>React Native has rich UI Components</li>
                                <li>Netflix, Instagram, Facebook and Walmart relies on React native.</li>
                                <li>Applications developed with react-native are same speed as native application.</li>
                                </ul>
                            </div>
                            <div className="col-md-5">
                                <ul className="bullet_ul">
                                <li>Cross-Platform app framework.</li>
                                <li>With "Hot Reloading" you can run new code while retaining your application state.</li>
                                <li>Less time to develop applications.</li>
                                </ul>
                            </div>
                            <div className="col-md-1">&nbsp;</div>
                            </div>
                        </div>
                        </div>
                        <div className="row react_about">
                        <div className="row tb_row">
                            <div className="desc">
                            <div className="container">
                                <h2 className="heading">Our React Native Development Services Includes</h2>
                                <p>Datage, provides React Native development services and provides offshore development services. Nobility and prowess in creating React Native applications for different verticals like m-commerce, healthcare, travel, etc. Our React Native developers deliver incredible products all over the world. Our React Native Development team builds extraordinary mobile apps across different domains.</p>
                            </div>
                            <div className="react_process_tools container">
                                <div className="row">
                                <div className="react_about_tools"><span><i className="fa fa-android" /></span>
                                    <p>React Native Andriod App</p>
                                </div>
                                <div className="react_about_tools"><span><i className="fa fa-apple" /></span>
                                    <p>React Native iOS App</p>
                                </div>
                                <div className="react_about_tools"><span><i className="fa fa-pencil" /></span>
                                    <p>React Native Customization Service</p>
                                </div>
                                <div className="react_about_tools"><span><i className="fa fa-paint-brush" /></span>
                                    <p>React Native UI/UX App</p>
                                </div>
                                <div className="react_about_tools"><span><i className="fa fa-life-ring" /></span>
                                    <p>React Native Support &amp; Maintaince</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="row react_development">
                        <div className="container">
                            <h3 className="heading">Why Hire React Native Developers From Datage?</h3>
                            <p>Hire React Native developers with vast experience from Datage. Our team of React Native developers provides state of the art solution to help businesses in marketing their products and services in no time. Here are some key reasons to hire our React Native developers for building successful React Native mobile Application.</p>
                            <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                <div className="col-md-4">
                                    <div className="react_developer"><img alt="react expert" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/react_expert1.png" /></div>
                                    <p className="react_images_text">Expertise In React Native Development</p>
                                </div>
                                <div className="col-md-4">
                                    <div className="react_developer"><img alt="On Time Project Delivery " src="https://d28k6hocvoxiuc.cloudfront.net/site/images/project-delivery.png" /></div>
                                    <p className="react_images_text">On Time Project Delivery</p>
                                </div>
                                <div className="col-md-4">
                                    <div className="react_developer"><img alt="Immediate Support and Maintenance" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/chat-support.png" /></div>
                                    <p className="react_images_text">Immediate Support and Maintenance</p>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                <div className="col-md-4">
                                    <div className="react_developer"><img alt="Quality Service" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/management.png" /></div>
                                    <p className="react_images_text">Quality Services</p>
                                </div>
                                <div className="col-md-4">
                                    <div className="react_developer"><img alt="client Satisfaction" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/rating.png" /></div>
                                    <p className="react_images_text">100% Client Satisfaction</p>
                                </div>
                                <div className="col-md-4">
                                    <div className="react_developer"><img alt="Hire dedicated Developers" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/human-resources.png" /></div>
                                    <p className="react_images_text">Hire Dedicated Developers</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="react_tools_quality row">
                        <div className="container">
                            <h2 className="heading">Our Process</h2>
                            <p>Datage follows Strategic methodologies to execute projects in result oriented way. Our process diagram is only simple representative, we execute project in detailed process to turn your idea into reality. Let's catch up over a skype or mail to discuss your idea.</p>
                            <div className="quality_image"><img className="img_max" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/mobile-app-process1.png" /></div>
                        </div>
                        </div>
                        
                        <div className="react_tools_quality row">
                        <div className="container">
                            <h2 className="heading">Our Tools</h2>
                            <div className="quality_image">
                            <div className="row">
                                <div className="col-md-6">
                                <p className="quality_code">Xcode is a complete developer toolset<br />
                                    for creating apps for Mac, iPhone,<br />
                                    iPad, Apple Watch, and Apple TV</p>
                                </div>
                                <div className="col-md-6">
                                <p className="quality_content">Android Studio provides the<br />
                                    fastest tools for building apps on<br />
                                    every type of Android device.</p>
                                </div>
                            </div>
                            <img alt="React Native Tools" className="img_max" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/react_oppo.png" />
                            <div className="row">
                                <div className="col-md-4">
                                <p>Provides Analytics, Real Time<br />
                                    Database, Cloud Messaging</p>
                                </div>
                                <div className="col-md-4">
                                <p>Redux is to manage the state<br />
                                    of an entire application.</p>
                                </div>
                                <div className="col-md-4">
                                <p>Webpack is a module bundler for<br />
                                    modern JavaScript applications.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-12 mp-services">
                        <h2 className="heading text-center">Related Services</h2>
                        <div className="container">
                        <div className="row">
                            <div className="col-md-4 black"><a href="https://datage.in/android-app-development-services/">Android App Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/python-development-services/">Python Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/reactjs-development-services/">ReactJS Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/angularjs-development-services/">AngularJS Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/django-oscar-ecommerce-development-services/">Django Oscar E-commerce Development</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/e-learning-software-development-services/">E-learning Software Development Services</a></div>
                        </div>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <div className="container">
                        
                        <div className="contact_container row justify-content-center">
                        <div className="col-md-12 col-lg-12">
                            <div className="contact_block row marl">
                            <div className="col-md-7 col-xs-12">
                            <Callout />
                            </div>
                            <div className="col-md-5 col-xs-12 col-sm-12">
                                <div className="contact_content"><span>React Native Development Company</span>
                                <p>We are one of the earliest adopters and expeditious in React Native, our team is fixed over delivering quality and flexible at customization. Our IT experts with wealth of resources respond effectively to your business demands. For global support and quick assistance we are always-on, businesses run 24 x 7.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
                <div className="col-md-12 customer_reviews">
                <div className="container">
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ReactNative
