import React from "react";

import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";

function ContactUsPage() {
	const { register, handleSubmit, errors } = useForm();
	const onSubmit = (data) => {
		console.log(data);
		const params = {
			site: "randm.in",
			from_name: data.name,
			to_name: "Admin",
			email: data.email,
			phone: data.phone,
			message: data.message,
			reply_to: data.email,
		};
		emailjs
			.send(
				"randm_gmail",
				"template_k404w7g",
				params,
				"user_m3FlgpwqQTqKfjb3LSnMK"
			)
			.then(
				(result) => {
					console.log(result.text);
                    alert("Thankyou for submitting the details. We will get back to you shortly.")
				},
				(error) => {
					console.log(error.text);
				}
			);
		/* sendContactForm({name, email, message})
            .then((r) => this.setState({ sent: true }))
            .catch((err) => console.log(err)); */
	};

	const styles = {
		background: "white",
		color: "red",
		fontSize: "1rem",
	};

	return (
		<div>
			<div className="scroller-inner">
				<div className="codrops-top clearfix" />
				<div className="content clearfix">
					<div
						id="container_wrapper"
						className="contact_container_wrapper"
					>
						{/* contact_row starts here */}
						<div className="contact_container">
							<div className="contact_header row marl">
								<div className="col-md-12">
									<h1 className="text-center heading  mt-5 ">
										Partner With Us
									</h1>
								</div>
								<div className="col-md-12">
									<div className="container">
										<div className="row justify-content-center marl">
											<div className="col-md-12">
												<form
													onSubmit={handleSubmit(
														onSubmit
													)}
													className="contact_form contactpage_form mt-4"
													id="contactform"
													method="post"
												>
													<div className="col-md-12 text-center intrested_contact">
														<p>
															<b>
																Join hands with
																us to expand
																your business
																horizons
															</b>
														</p>
														<p>
															Let's connect and
															get started!
														</p>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<input
																	ref={register(
																		{
																			required: true,
																		}
																	)}
																	type="text"
																	className="form-control"
																	placeholder="Enter your name"
																	id="full_name"
																	name="name"
																/>
																{errors.name && (
																	<p
																		style={
																			styles
																		}
																	>
																		Full
																		Name is
																		required
																	</p>
																)}
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<input
																	ref={register(
																		{
																			required: true,
																		}
																	)}
																	type="text"
																	className="form-control"
																	placeholder="Phone number"
																	id="phone"
																	name="phone"
																/>
																{errors.phone && (
																	<p
																		style={
																			styles
																		}
																	>
																		Phone
																		number
																		is
																		required
																	</p>
																)}
															</div>
														</div>
													</div>
													<div className="row ">
														<div className="col-md-12">
															<div className="form-group">
																<input
																	type="text"
																	className="form-control"
																	placeholder="Email Address"
																	id="email"
																	name="email"
																	ref={register(
																		{
																			required: true,
																		}
																	)}
																/>
																{errors.email && (
																	<p
																		style={
																			styles
																		}
																	>
																		Email
																		address
																		is
																		required
																	</p>
																)}
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12">
															<div className="form-group">
																<input
																	type="text"
																	className="form-control"
																	placeholder="Your Message"
																	id="message"
																	name="message"
																	ref={register(
																		{
																			required: true,
																		}
																	)}
																/>
																{errors.message && (
																	<p
																		style={
																			styles
																		}
																	>
																		This
																		Message
																		is
																		required
																	</p>
																)}
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12">
															<div className="form-group text-center">
																<button
																	type="submit"
																	className="btn primary_btn"
																>
																	Submit
																	Request
																</button>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12 reach_contact">
									<div className="container">
										<div className="row marl no-gutters justify-content-center">
											<div className="col-md-12">
												<div className="heading jost text-center">
													Reach Us
												</div>
											</div>
											<div className="col-md-9 col-12">
												<div className="row address_block marl">
													<div className="col-md-8">
														<div className="address">
															<p>
																4th Floor, No
																22, Salarpuria
																Towers-I, Hosur
																Rd, 7th Block,
																Koramangala,
																Bengaluru,
																Karnataka 560095{" "}
															</p>
															<a
																target="_blank"
																href="https://g.page/91springboardforumkoramangala7?share"
																className="jost"
															>
																Get Directions
															</a>
														</div>
													</div>
													<div className="pl-4 col-md-4 contact_email_address m-0 text-left">
														<span className="icon">
															<i
																className="far fa-envelope"
																aria-hidden="true"
															/>
														</span>
														<p>
															<a href="mailto:hello@datage.in">
																hello@datage.in
															</a>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ContactUsPage;
