import React from 'react'
import Callout from 'components/Callout'

function Python() {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="python_service_devloment row">
                        <div className="python_development_service">
                        <div className="container">
                            <div className="service_banner row ">
                            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 python_mp_banner" style={{height: 'auto'}}>
                                <div className="python_skills"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/python_mp-developmen.png" style={{position: 'inherit'}} />
                                <div style={{position: 'absolute', top: 0}}>
                                    <h1 className="heading mb-0">Python Development Services</h1>
                                    <h2 className="heading">Python?don't worry we tamed it to do awesome</h2>
                                    <p>From scraping to developing web application we use Python at every level. Our Major python skill includes Django which we delivered several projects. We love to add our python skills to your idea.&nbsp;Hire our python and <a href="https://datage.in/django-development-services/">django development company</a> to outsource your software projects.</p>
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 live_projects">
                                <div className="row no-gutters mp_python_projects">
                                <div className="col-md-4"><span>10+</span>
                                    <p>live projects</p>
                                </div>
                                <div className="col-md-4"><span>5+</span>
                                    <p>Python meetups</p>
                                </div>
                                <div className="col-md-4"><span>2+</span>
                                    <p>Pip packages</p>
                                </div>
                                </div>
                                <div className="col-lg-12 col-md-4 text-center python_skill_logo"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/python_s.png" /></div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="container">
                        <div className="python_strength_automation row no-gutters justify-content-center">
                            <div className="p_s_a_table">
                            <div className="col-md-12 col-sm-12 automation_container">
                                <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="row no-gutters development_block">
                                    <div className="col-md-3 auto_development"><img className="python_tools" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/development.png" /></div>
                                    <div className="col-md-9 block"><strong>Web Development</strong>
                                        <p>We build powerful web applications using Django for financial services, E-commerce, Travel, information &amp; education industries.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="row no-gutters development_block">
                                    <div className="col-md-3 auto_development"><img className="python_tools" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/data_science.png" /></div>
                                    <div className="col-md-9 block"><strong>Data science</strong>
                                        <p>To make data-driven business decisions we help you to analyze your data using python tools scipy, numpy, pandas.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="row no-gutters development_block">
                                    <div className="col-md-3 auto_development"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/raspberry.jpg" /></div>
                                    <div className="col-md-9 block"><strong>Raspberry PI</strong>
                                        <p>We use a&nbsp;raspberry pi as a streaming device, Raspberry pi with an external microphone can stream your voice with our App "Radio String" to all over the internet.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="row no-gutters development_block">
                                    <div className="col-md-3 auto_development"><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/test-service.png" /></div>
                                    <div className="col-md-9 block"><strong>Testing</strong>
                                        <p>Every project, we write unit tests and functional tests using selenium. Most of our code is tested automatically on GitLab-Ci, Travis, Jenkins-CI making it bug-free for every tiny change.</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="container">
                            <div className="service_advantages row justify-content-center">
                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 django_expert">
                                <h2 className="heading">The Services Offered by our Python Development Company</h2>
                                <p>Take a look at some of the services our Python Development Company offers according to your Development Needs:</p>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-lg-6">
                                    <ul>
                                        <li>Web Application Development</li>
                                        <li>Python Programming</li>
                                        <li>REST API development Using <a href="https://datage.in/django-rest-framework-development-services/" style={{color: '#337ab7'}}>Django Rest Framework</a></li>
                                        <li>CMS Development</li>
                                    </ul>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-lg-6">
                                    <ul>
                                        <li><a href="https://datage.in/ecommerce-web-development-services/" style={{color: '#337ab7'}}>E-commerce development</a></li>
                                        <li>E-commerce Marketplace</li>
                                        <li><a href="https://datage.in/e-learning-software-development-services/" style={{color: '#337ab7'}}>E-learning Development</a></li>
                                        <li>Machine learning using python libraries</li>
                                        <li><a href="https://datage.in/website-maintenance-services/" style={{color: '#337ab7'}}>Web Application support and maintenance</a></li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="container">
                            <div className="service_banner row justify-content-center">
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                <h2 className="heading">Datage for Python Development Services USA, India</h2>
                                <p>Datage has 6+ years of experience in solving complex problems using python with a dedicated team of python developers. We provide services including Web apps, Data Science, automating tasks, testing. We have proven track record in achieving client goals and continuous providing extensive Python development services. In Datage you can hire python developers who have expertise in python skills and complex&nbsp;problem solving &nbsp;skills. Contact our python development company and start delivering high-quality software to customers.</p>
                                <p>Take our help in reaching your goal. Contact us at <a href="mailto:hello@datage.in">hello@datage.in</a></p>
                                </div>
                            </div>
                            </div>
                            <div className="col-md-12 mp-services"><span className="heading">Related Services</span>
                            <div>
                                <div className="row">
                                <div className="col-md-4 black"><a href="https://datage.in/django-development-services/">Django Development Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/reactjs-development-services/">ReactJS Development Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/angularjs-development-services/">AngularJS Development Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/django-oscar-development-services/">Django-oscar E-commerce Development Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/e-learning-software-development-services/">E-learning Software Development Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website maintenance Services</a></div>
                                </div>
                            </div>
                            </div>
                            {/* open_source_item ends here */}</div>
                        
                        &nbsp;
                        <div className="contact_container row justify-content-center">
                            <div className="col-md-12 col-sm-12 col-lg-12">
                            <div className="contact_block row marl no-gutters" style={{padding: 20}}>
                                <div className="col-md-7 col-xs-12">
                                <Callout />
                                </div>
                                <div className="col-md-5 col-xs-12 col-sm-12">
                                <div className="contact_content"><span className="jost">Python Software Development Services.</span>
                                    <p>Datage offer services ranging from simple scripts to complete enterprise web application and not to mention server/infrastructure orchestration using ansible. Our main goal is to provide high-class python software development and maintenance services by ever learning and contributing to open source communities.</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
                <div className="col-md-12 customer_reviews">
                <div className="container">
                </div>
                </div>
            </div>
      </div>
 </div>
    )
}

export default Python
