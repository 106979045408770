
import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';


const items = [
  {
  id: 1,
  altText: 'Datage is a great place to go if you are looking for passion as an ingredient for your enterprise or project. They understand the future and the craft of manifesting your vision. In our case they actually made one of our dreams come to life.' ,
  author: 'Brij Purhit, Co-Founder SellerApp'
  },
  {
    id: 2,
    altText: 'The Datage team forms a strong partnership. They are our valued development partners specifically because they have a passion for inclusive finance that cannot be matched by most development houses. They are competent with unique ideas and thorough in their process. They are honest, patient and communicate very well and hold your hand through challenges to give you reassurance. I would recommend them to all startups',
    author: 'Ashwin, Director - ActivDoctorsOnline'
  },
  {
   id: 3,
   altText: 'Datage Technologies has consistently and professionally delivered to my ever-changing specifications. Project changes are met with cheerfulness and enthusiasm. They are quick to deliver. They have an in-depth knowledge of the industry and what it takes to professionally build and manage code intensive websites.' ,
   author: 'Sitaraman Kalyan, Product Head - Riskcovry'
  } 
];

const Testimonials = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const styles= {
   color:  '#4c4c4c',
   textAlign: 'center'
}

  const next = ( ) => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = ( ) => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
    <CarouselItem
        onExiting={( ) => setAnimating(true)}
        onExited={( ) => setAnimating(false)}
        key={item.id}
    >
       <strong style={styles} >{item.author}</strong>
       <p>{item.altText}</p>
       </CarouselItem>
    );
  });

  return (
     <div>
        <style>
   {
     `.custom-tag {
         max-width: 100%;
         height: 500px;
         background: black;
       }`
   }
 </style>
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>

     </div>
   
  );
}

export default Testimonials;
/*function Testimonials( ) {
    return (
    
       <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <section class="testnomials">
                     <div class="">
                        <h2 class="heading">Testimonials</h2>
                        <div class="row marl no-gutters justify-content-center">
                           <div class="col-lg-11 col-xl-12 col-md-12 col-sm-10">
                              <div class="testi_swipe slick-initialized slick-slider slick-dotted"><button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: inline-block;"></button>
                                 <div class="slick-list" style="height: 187px;"><div class="slick-track" style="opacity: 1; width: 5364px; transform: translate3d(-596px, 0px, 0px);"><div class="item slick-slide slick-cloned" data-slick-index="-1" id="" aria-hidden="true" tabindex="-1" style="width: 596px;">
                                    <strong>Sitaraman Lakshminarayanan</strong>
                                    <p>Datage team is a life saver. Found them after trying multiple development teams for Python Django development and they are best to work with and highly talented. They know how to deploy apps to AWS. They understand customer /user needs and you can count on them to support you when you need them. My quick tip to those considering them is to have a plan so you can take advantage of their skills and resources to execute. They are really startup friendly team, no bureaucracy and don’t have to deal with multiple levels.</p>
                                 </div><div class="item slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" tabindex="0" role="tabpanel" id="slick-slide00" aria-describedby="slick-slide-control00" style="width: 596px;">
                                    <strong> Sechaba</strong>
                                    <p>The Datage team forms a strong partnership. They are our valued development partners specifically because they have a passion for inclusive finance that cannot be matched by most development houses. They are competent with unique ideas and thorough in their process. They are honest, patient and communicate very well and hold your hand through challenges to give you reassurance. I would recommend them to all startups.</p>
                                 </div><div class="item slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide01" aria-describedby="slick-slide-control01" style="width: 596px;">
                                    <strong>Diego V Lozano</strong>
                                    <p>Datage is a great place to go if you are looking for passion as an ingredient for your enterprise or project. They understand the future and the craft of manifesting your vision. In our case they actually made one of our dreams come to life.</p>
                                 </div><div class="item slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide02" aria-describedby="slick-slide-control02" style="width: 596px;">
                                    <strong>Brian</strong>
                                    <p>Datage has consistently and professionally delivered to my ever-changing specifications. Project changes are met with cheerfulness and enthusiasm. They are quick to deliver. They have an in-depth knowledge of the industry and what it takes to professionally build and manage code intensive websites.</p>
                                 </div><div class="item slick-slide" data-slick-index="3" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide03" aria-describedby="slick-slide-control03" style="width: 596px;">
                                    <strong>Sitaraman Lakshminarayanan</strong>
                                    <p>Datage team is a life saver. Found them after trying multiple development teams for Python Django development and they are best to work with and highly talented. They know how to deploy apps to AWS. They understand customer /user needs and you can count on them to support you when you need them. My quick tip to those considering them is to have a plan so you can take advantage of their skills and resources to execute. They are really startup friendly team, no bureaucracy and don’t have to deal with multiple levels.</p>
                                 </div><div class="item slick-slide slick-cloned" data-slick-index="4" id="" aria-hidden="true" tabindex="-1" style="width: 596px;">
                                    <strong> Sechaba</strong>
                                    <p>The Datage team forms a strong partnership. They are our valued development partners specifically because they have a passion for inclusive finance that cannot be matched by most development houses. They are competent with unique ideas and thorough in their process. They are honest, patient and communicate very well and hold your hand through challenges to give you reassurance. I would recommend them to all startups.</p>
                                 </div><div class="item slick-slide slick-cloned" data-slick-index="5" id="" aria-hidden="true" tabindex="-1" style="width: 596px;">
                                    <strong>Diego V Lozano</strong>
                                    <p>Datage is a great place to go if you are looking for passion as an ingredient for your enterprise or project. They understand the future and the craft of manifesting your vision. In our case they actually made one of our dreams come to life.</p>
                                 </div><div class="item slick-slide slick-cloned" data-slick-index="6" id="" aria-hidden="true" tabindex="-1" style="width: 596px;">
                                    <strong>Brian</strong>
                                    <p>Datage has consistently and professionally delivered to my ever-changing specifications. Project changes are met with cheerfulness and enthusiasm. They are quick to deliver. They have an in-depth knowledge of the industry and what it takes to professionally build and manage code intensive websites.</p>
                                 </div><div class="item slick-slide slick-cloned" data-slick-index="7" id="" aria-hidden="true" tabindex="-1" style="width: 596px;">
                                    <strong>Sitaraman Lakshminarayanan</strong>
                                    <p>Datage team is a life saver. Found them after trying multiple development teams for Python Django development and they are best to work with and highly talented. They know how to deploy apps to AWS. They understand customer /user needs and you can count on them to support you when you need them. My quick tip to those considering them is to have a plan so you can take advantage of their skills and resources to execute. They are really startup friendly team, no bureaucracy and don’t have to deal with multiple levels.</p>
                                 </div></div></div>
                                 
                                 
                                 
                              <button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: inline-block;"></button><ul class="slick-dots" style="display: block;" role="tablist"><li class="slick-active" role="presentation"><button type="button" role="tab" id="slick-slide-control00" aria-controls="slick-slide00" aria-label="1 of 4" tabindex="0" aria-selected="true">1</button></li><li role="presentation" class=""><button type="button" role="tab" id="slick-slide-control01" aria-controls="slick-slide01" aria-label="2 of 4" tabindex="-1">2</button></li><li role="presentation" class=""><button type="button" role="tab" id="slick-slide-control02" aria-controls="slick-slide02" aria-label="3 of 4" tabindex="-1">3</button></li><li role="presentation" class=""><button type="button" role="tab" id="slick-slide-control03" aria-controls="slick-slide03" aria-label="4 of 4" tabindex="-1">4</button></li></ul></div>
                           </div>
                        </div>
                        <i class="fa fa-quote-right"></i>
                     </div>
                  </section>
                  <!-- testnomials -->
               </div>
}

export default Testimonials*/
