import React from 'react'

function DesignUiSection( ) {
    return (
        <div>
            <section className="web_design custom_manage">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-md-6 mobile_responsive">
                        <img loading="lazy" src="/assets/img/new-images/ui_ux_service1_hom.png" width="100%" alt="Web Design" />
                    </div>
                    <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                        <h2 className="heading">Web Design UI &amp; UX
                        </h2>
                        <div className="description_block">
                        <p>Our design team excels at creating simple and delightful user experiences using user-centered design principals. Our goal is to deliver intuitive design solutions that 
                            form a bridge between business and customers.
                        </p>
                        <p>We believe web design is not just about beautiful designs, It’s about providing business solutions and makes a brand stand out from its competitors.
                            We have proven skills in Figma, Wordpress, Gatsby, Strapi, Bootstrap, Material Design, SASS and popular frameworks.
                        </p>
                        <ul>
                            <li>Website Ideation</li>
                            <li>User Experience Design</li>
                            <li>User Interface Design</li>
                            <li>Static, Dynamic or Hybrid</li>
                            <li>Wireframes and Prototyping</li>
                        </ul>
                        </div>
                        <div className="read_more_app">
                        <a href="/responsive-web-design/" target="_blank"><strong>Read More</strong></a>
                        </div>
                    </div>
                    <div className="col-md-6 desktop_responsive">
                        <img loading="lazy" src="/assets/img/new-images/ui_ux_service1_hom.png" width="100%" alt="Web Design" />
                    </div>
                    </div>
                </div>
                </section>
        </div>
    )
}

export default DesignUiSection;
