import React from 'react'
import Callout from 'components/Callout'

function AWSConsulting( ) {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="aws_service_roles" style={{width: '100%'}}>
                        <div className="seo_banner react_js_banner partner_network row justify-content-center">
                        <div className="col-lg-10 col-md-12">
                            <div className="row">
                            <div className="col-md-5">
                                <div className="logo" style={{textAlign: 'center'}}><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws-standard-consulting-partner.jpg" style={{marginTop: 15}} />
                                <p className="aws_partner_text">Standard Consulting Partner</p>
                                </div>
                            </div>
                            <div className="col-md-7 ">
                                <h1 className="content">AWS Consulting &amp; Development Services</h1>
                                <p>Scale &amp; Optimize your application with AWS Services</p>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="service_banner aws_service_banner row marl">
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-8 col-md-12 col-sm-8">
                                <h2 className="heading">Build Your Web Application With Powerful Amazon Web Services(AWS).</h2>
                                <p>We are experienced professionals in building web &amp; mobile applications using sundry AWS Consulting services. Our certified developers build secure, scalable and high-performance applications. We deliver innovative solutions and transform complex experiences into products customers love. We provide ongoing support to create, deliver strategies and manage solutions to maximize your investment through AWS services.</p>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-4 left_content text-center"><img alt="Amazon Cloud Services" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_cl.png" /></div>
                            </div>
                        </div>
                        </div>
                        <div className="container">
                        <div className="aws_services row">
                            <div className="aws_row">
                            <div className="col-md-12">
                                <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="aws_item">
                                    <div className="logo"><img alt="Amazon EC2" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_ec2.png" /></div>
                                    <div className="desc"><strong>Amazon EC2</strong>
                                        <p>We are specialized in creating virtual servers within minutes with maximum throughput.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="aws_item">
                                    <div className="logo"><img alt="Amazon VPC" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_vpc.png" /></div>
                                    <div className="desc"><strong>Amazon VPC</strong>
                                        <p>We manages your private cloud easilt in an cost-effective way</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="aws_item">
                                    <div className="logo"><img alt="Amazon s3" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_s3.png" /></div>
                                    <div className="desc"><strong><a href="https://datage.in/amazon-storage-services/" style={{color: 'blue'}}>Amazon s3</a></strong>
                                        <p>We easily manage high storage system for your global networking of sites.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="aws_item">
                                    <div className="logo"><img alt="Amazon Glacier<" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_glacier.png" /></div>
                                    <div className="desc"><strong>Amazon Glacier</strong>
                                        <p>We manage long term usage of your data as archival in an inexpensive way.</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="row aws_row row_2 justify-content-center">
                            <div className="col-md-12 col-xs-12">
                                <div className="row no-gutters">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="aws_item">
                                    <div className="logo"><img alt="Amazon Elasticache" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_elc.png" /></div>
                                    <div className="desc"><strong>Amazon Elasticache</strong>
                                        <p>We manage caching of apps by using redis, memcached to provide high performance</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="aws_item">
                                    <div className="logo"><img alt="Amazon RDS" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_rds.png" /></div>
                                    <div className="desc"><strong>Amazon RDS</strong>
                                        <p>We’re specialized to setup, scale your database for easyuse, high performance.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="aws_item">
                                    <div className="logo"><img alt="Amazon Route 53" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_r_53.png" /></div>
                                    <div className="desc"><strong>Amazon Route 53</strong>
                                        <p>We’ve experience in managing your dns records, hosted zones, dns queries in an easy way.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="aws_item">
                                    <div className="logo"><img alt="Amazon CloudFront" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_cf.png" /></div>
                                    <div className="desc"><strong>Amazon CloudFront</strong>
                                        <p>We’ve expertised in speeds up distribution of your static and dynamic web content to your users in a less time</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="row aws_row row_2 justify-content-center">
                            <div className="col-md-12 col-xs-12">
                                <div className="row no-gutters">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="aws_item">
                                    <div className="logo"><img alt="Amazon SES" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_ses.png" /></div>
                                    <div className="desc"><strong><a href="https://datage.in/amazon-notification-services/" style={{color: 'blue'}}>Amazon SES</a></strong>
                                        <p>We can easily manage your marketing, promotional emails with lower bounce rate, receive emails from users.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="aws_item">
                                    <div className="logo"><img alt="Amazon OpsWorks DevOps" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_ops.png" /></div>
                                    <div className="desc"><strong>Amazon OpsWorks DevOps</strong>
                                        <p>Adds new servers within minutes</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="aws_item">
                                    <div className="logo"><img alt="Amazon SQS" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_sqs.png" /></div>
                                    <div className="desc"><strong>Amazon SQS</strong>
                                        <p>We’re specialized in managing your large volumes of your data with sqs.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="aws_item">
                                    <div className="logo"><img alt="Amazon SNS" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_sns.png" /></div>
                                    <div className="desc"><strong>Amazon SNS</strong>
                                        <p>we’ve expereince in sending push notifications to massive users with low cost.</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="need_testing_container django_ecommerce_list row ">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="need_testing_block "><span>Benefits of AWS</span>
                                <ul>
                                    <li>Easy To use &amp; Pay as you go</li>
                                    <li>Seamless interaction with experienced support team</li>
                                    <li>Scaling of your application</li>
                                    <li>High Performance &amp; security</li>
                                    <li>High Flexiblity with seamless no of options</li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="need_testing_block "><span>Our AWS Development Services</span>
                                <ul>
                                    <li>AWS Database Services</li>
                                    <li>Artificial Intelligence &amp; IOT</li>
                                    <li>AWS Deployment Services</li>
                                    <li>AWS Supporting services</li>
                                    <li>AWS Administration Services</li>
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="service_qualities why_to_choose aws_why_to_choose row">
                        <div className="container">
                            <div className="row text-center">
                            <h2 className="heading">How AWS Development Services helps to build your application?</h2>
                            </div>
                            <div className="col-md-12">
                            <div className="desc text-center">
                                <p>Our AWS certified professionals with hands-on expertise and knowledge of AWS comply to manage all your scalable enterprise application requirements through AWS Development Services.</p>
                                <p>Our enterprise level application development framework enables the engineers at Datage to develop and collaborate applications by testing their performance using a cohesive development platform. We also develop applications end-to-end with the best possible way of interaction.</p>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="how_we_do_container mongo_business_col row marl">
                        <div className="container">
                            <h2 className="heading">How we do</h2>
                            <p>We’ll provide 24*7 support to provide high performance, excellence towards the work.</p>
                            <div className="row how_we_do_col justify-content-center">{/* we_do_block starts here */}
                            <div className="col-md-2 col-sm-2 each_row">
                                <div className="we_do_block">
                                <div className="icon"><i className="fa fa-cloud" /></div>
                                <strong>We Code</strong></div>
                            </div>
                            {/* we_do_block ends here */}{/* we_do_block starts here */}
                            <div className="col-md-2 col-sm-2 each_row">
                                <div className="we_do_block">
                                <div className="icon"><i className="fa fa-lightbulb-o" /></div>
                                <strong>We Develop</strong></div>
                            </div>
                            {/* we_do_block ends here */}{/* we_do_block starts here */}
                            <div className="col-md-2 col-sm-2 each_row">
                                <div className="we_do_block">
                                <div className="icon"><i aria-hidden="true" className="fa fa-shield" /></div>
                                <strong>We Secure</strong></div>
                            </div>
                            <div className="col-md-2 col-sm-2 each_row">
                                <div className="we_do_block">
                                <div className="icon"><i aria-hidden="true" className="fa fa-handshake-o" /></div>
                                <strong>We Care</strong></div>
                            </div>
                            {/* we_do_block ends here */}{/* we_do_block starts here */}
                            <div className="col-md-2 col-sm-2 each_row">
                                <div className="we_do_block">
                                <div className="icon"><i aria-hidden="true" className="fa fa-clock-o" /></div>
                                <strong>We Deliver</strong></div>
                            </div>
                            {/* we_do_block ends here */}</div>
                        </div>
                        </div>
                        <div className="service_qualities why_to_choose aws_why_to_choose row">
                        <div className="container">
                            <h2 className="heading">Why To Choose Datage For Amazon Development Services?</h2>
                            <div className="col-md-12">
                            <div className="desc text-center">
                                <p>Datage is a <a href="https://datage.in/uk/aws-consulting-partner/">Standard Consulting Partner</a>, offers all types of aws services to its clients and forms a distinguished client base over the years. Our technical expertise and dedicated team delivers high quality product to clients, integrate all types of aws services to your existing applications and products. Our team has expertise to develop your application from scratch and figure out best from all the possible ways to develop an excellent web application. Our team mainly focus on scaling, performance, quality of a product to give maximum user statisfaction.</p>
                                <p>Are you looking for&nbsp;<strong>AWS development services</strong>&nbsp;then you came to the right place. Contact us now at&nbsp;<a href="mailto:hello@datage.in" style={{color: 'rgb(51, 122, 183)', cursor: 'pointer'}}>hello@datage.in</a>&nbsp;or mail directly to get our dedicated assistance</p>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="container">
                        <div className="col-md-12 services_page_blogs">
                            <h2>What are the benefits of AWS?</h2>
                            <p>AWS makes it easy to deploy, scale, monitor and secure your infrastructure in cloud. It's easy for businesses to estimate their cloud spending costs. Infrastructure is robust and suit all needs of businesses from startup to enterprise.</p>
                            <h2>What is an AWS Partner?</h2>
                            <p>AWS partner can help you with best practices of managing your infrastructure on AWS servers. Working with a certified AWS partner has benefits of cost savings, AWS infrastructure planning and optimisation, securing applications. Most importantly, AWS partner know how to do a particular task in efficient way on AWS infrastructure.</p>
                            <h2>What are the AWS Services Available?</h2>
                            <p>As of 11 Apr, 2018, there are total 102 distinct services available and more services being added frequently to give extra powers to your applications. some of the popular AWS services most applications use are:</p>
                            <div className="row">
                            <div className="col-md-4">
                                <ul>
                                <li>AWS Compute Services</li>
                                <li>AWS Database services</li>
                                <li>AWS Storage Services</li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul>
                                <li>AWS Analytics Services</li>
                                <li>AWS Networking Services</li>
                                <li>AWS Deployment Services</li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul>
                                <li>AWS Cloud Security Services</li>
                                <li>AWS Supporting services</li>
                                <li>AWS Administration Services</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-12 aws_vedio_section">
                        <div className="container">
                            <video controls="controls" height="400px;" id="video" poster="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_partner_network.png" preload="none" width="100%"><source id="mp4" src="https://datage.in/static/videos/aws_video.mp4" type="video/mp4" /></video>
                        </div>
                        </div>
                        
                        <div className="col-md-12 mp-services" style={{background: '#e9f3f7', margin: '2rem 0px', paddingBottom: '1rem'}}>
                        <div className="container">
                            <h2 className="heading">Related Services</h2>
                            <div>
                            <div className="row">
                                <div className="col-md-4 black"><a href="https://datage.in/amazon-simple-storage-services/">Amazon S3 Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/amazon-notification-services/">Amazon SES Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/aws-consulting-partner/">AWS Consulting Partner</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/server-maintenance-services/">Server Maintenance Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/docker-consulting/">Docker Consulting Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website Maintenance Services</a></div>
                            </div>
                            </div>
                        </div>
                        </div>
                        
                    </div>
                    <div className="container">
                        <div className="contact_container aws_contact row justify-content-center">
                        <div className="col-md-12 col-sm-12  col-lg-12">
                            <div className="contact_block row marl">
                            <div className="col-lg-5 col-md-12 col-xs-12">
                                <Callout />
                            </div>
                            <div className="col-md-7 col-xs-12 col-sm-12">
                                <div className="contact_content"><span>AWS Consulting Services.</span>
                                <p>We believe our smart work and dedication as a key to our success. We at Datage, follow a dynamic approach, easily build your application from the scratch by managing all aws services to satisfy all your requirements. We can help you boost up your business by outsourcing the vast Amazon web services. Contact Our team of dedicated aws developers to build, host your application by Datage.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                    </div>
                    </div>
                </div>
                <div className="col-md-12 customer_reviews">
                    <div className="container">
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default AWSConsulting
