import { Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer/Footer';
import HomePage from './Pages/HomePage';
import PortfolioPage from './Pages/PortfolioPage';
import FaqPage from './Pages/FaqPage';
import OffersPage from './Pages/OffersPage';
import WebinarPage from './Pages/WebinarPage';
import TechStackPage from './Pages/TechStackPage';
import ContactUsPage from './Pages/ContactUsPage';

import  python from './components/Services/Python';
import Django from './components/Services/Django';
import ServiceReact from './components/Services/ServiceReact';
import Angular from './components/Services/Angular';
import postgreSQL from './components/Services/PostgreSQL';
import Ecommerce from './components/Services/Ecommerce';
import DjangoRest from './components/Services/DjangoRest';
import MongoDb from './components/Services/MongoDb';
import Learning from './components/Services/Learning';
import Oscar from './components/Services/Oscar';
import Android from './components/Services/MobileDevelopment/Android';
import ReactNative from './components/Services/MobileDevelopment/ReactNative';
import WebDesign from './components/Services/Ui-Ux-Design/WebDesign';
import BootStrap from './components/Services/Ui-Ux-Design/BootStrap';
import AmpDesign from './components/Services/Ui-Ux-Design/AmpDesign';


import AWSConsulting from './components/OtherServices/AWSConsulting';
import ConsultingPartner from './components/OtherServices/ConsultingPartner';
import Docker from './components/OtherServices/Docker';
import ServerMaintenance from './components/OtherServices/ServerMaintenance';
import  SimpleNotification from './components/OtherServices/SimpleNotification';
import WebsiteMaintenance from './components/OtherServices/SimpleNotification';
import SimpleStorage from './components/OtherServices/SimpleStorage';
import ScrollToTop from './components/ScrollToTop';

function App( ) {
  return (
    <div className="app">
        <div className="wrapper">
            <div id="fullpage">
                < Header />
                <ScrollToTop>
                  <Switch>
                    <Route exact path='/' component={HomePage} />
                    <Route  path='/our-portfolio'   component={PortfolioPage} />
                    <Route  path='/faqs' component={FaqPage} />
                    <Route  path='/offers'   component={OffersPage} />
                    <Route  path='/webinars' component={WebinarPage} />
                    <Route  path='/our-tech-stack'   component={TechStackPage} />
                    <Route  path='/contact-us'   component={ContactUsPage}/>
                    
                    <Route  path='/python-development-services'  component={  python} />
                    <Route  path='/django-development-services'  component={ Django} />
                    <Route  path='/reactjs-development-services'  exact component={ ServiceReact }/>
                    <Route  path='/angularjs-development-services' exact component={ Angular } />
                    <Route  path='/postgresql-administration-and-management' exact component={ postgreSQL } />
                    <Route  path='/ecommerce-web-development-services' exact component={Ecommerce } />
                    <Route  path='/django-rest-framework-development-services' exact component={ DjangoRest } />
                    <Route  path='/mongodb-development-services' exact component={ MongoDb } />
                    <Route  path='/e-learning-software-development-services' component={ Learning } />
                    <Route  path='/django-oscar-development-services' exact component={ Oscar } />
                    <Route  path='/android-app-development-services' component={ Android } />
                    <Route  path='/react-native-development-services/' component={ ReactNative } />
                    <Route  path='/responsive-web-design' exact component={WebDesign } />
                    <Route  path='/bootstrap-web-development' component={ BootStrap } />
                    <Route  path='/amp-design-development-services' component={ AmpDesign } />


                    <Route path='/aws-consulting-services' component={ AWSConsulting  } />
                    <Route path='/amazon-simple-storage-services' component={ SimpleStorage } />
                    <Route path='/amazon-notification-services' component={ SimpleNotification } />
                    <Route path='/aws-consulting-partner' component={ ConsultingPartner } />
                    <Route path='/docker-consulting' component={ Docker } />
                    <Route path='/server-maintenance-services' component={ ServerMaintenance } />
                    <Route path='/website-maintenance-services' component={ WebsiteMaintenance} />
                   </Switch>
                   </ScrollToTop>
                  <Footer />
           </div>
        </div>
    </div>
  );
}

export default App;
