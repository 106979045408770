import React from "react";
import { Link } from "react-router-dom";
import "./FooterContact.css";

function FooterContact() {
	return (
		<div>
			<div className="row marl">
				<div className="col-md-12 contact_block m-0 p-3" style={{ background: "#fcfcfc"}}>
					<div className="row marl">
						<div className="col-md-12 text-center intrested_contact">
							<p className="heading_tag">
								<b>Interested in working with us?</b>
							</p>
							<Link to="/contact-us/" className="redirect_contact">
								<i
									className="fas fa-phone-alt"
									aria-hidden="true"
								/>{" "}
								Let's Talk And Get Started{" "}
								<i
									className="fas fa-angle-right"
									aria-hidden="true"
								/>
							</Link>
						</div>
					</div>
					<div className="row marl no-gutters justify-content-between d-none">
						<div className="col-md-4 left_block">
							<p className="heading">
								Get in touch - give your project details
							</p>
							<div className="email_block">
								<div className="icon">
									<i className="far fa-envelope" />
								</div>
								<div className="text">
									<p className="mt-0">Mail to our business team</p>
									<a href="mailto:hello@datage.in">
										<span>hello@datage.in</span>
									</a>
								</div>
							</div>
							</div>
						<div className="col-md-7">
                        <p className="heading">
								Get in touch - give your project details
							</p>
							<div className="email_block">
								<div className="icon">
									<i className="far fa-envelope" />
								</div>
								<div className="text">
									<p className="mt-0">Find us Here</p>
									<a href="https://g.page/91springboardforumkoramangala7?share">
										<span>4th Floor, No 22, Salarpuria Towers-I, Hosur Rd, 7th Block, Koramangala, Bengaluru, Karnataka 560095</span>
									</a>
								</div>
							</div>
                            
                        </div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FooterContact;
