import React from 'react'

import HeadingMenu from '../components/HeadingMenu';
import Sections from '../components/Sections/Sections'

function HomePage ( ) {
    return (
        <div>
             <header id="section0">
                <HeadingMenu />
            </header>
               <Sections />
        </div>
    )
}

export default HomePage;
