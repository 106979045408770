import Callout from 'components/Callout'
import React from 'react'

function Android() {
    return (
        <div>
         <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="seo_banner row">{/* service_banner starts here */}
                        <div className="service_banner row no-gutters justify-content-center">
                        <div className="col-lg-8 col-md-8">
                            <h1 className="heading text-center">Android App Development</h1>
                            <div className="content">
                            <p className="text-center">We Offer Custom Android App Development Services</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="service_qualities why_to_choose row" style={{paddingTop: 10, paddingBottom: 10, marginTop: 15}}>
                        <div className="container">
                        <div className="desc">
                            <div className="row text-center">
                            <h2 className="heading hundred_percentage text-center">Why Android App?</h2>
                            </div>
                            <div className="row">
                            <div className="col-md-12">
                                <p>More than 80% of smartphone running with Android OS. By building an android app for Your business has a chance to reach 2 billion active Android users around the globe.</p>
                            </div>
                            </div>
                        </div>
                        <div className="clearfix">&nbsp;</div>
                        <div className="row text-center">
                            <h2 className="heading hundred_percentage text-center">Why choose Datage for Android App development?</h2>
                        </div>
                        <div className="col-md-12">
                            <div className="desc">
                            <p>Datage is the Android App development company. We deliver successful apps with high quality, security, user-friendly and scalable for millions of active users.</p>
                            <h4 className="heading text-center">Our specialties:</h4>
                            <ul className="bullet_ul">
                                <li>User-Friendly Design, Easy Navigation: We design app in the end users perspective. We want your product to be easily used by your customers</li>
                                <li>Compatible With All Versions Of Android: In the development and Testing stages we test the product in all Android versions starting from Android 4.0 to latest android version.</li>
                                <li>Focus On Device Compatibility: We test app on all screen sizes and Android flavors.</li>
                                <li>Device Hardware Integrations: Our Team is expert in integrating Bluetooth, GPS, Fingerprint scan etc.</li>
                                <li>Latest In Technology: We use latest Tools to develop your product.</li>
                                <li>Offline Support: Some apps need offline support. Users wants to see information when they last connected to the internet. App data will be updated once they connect to the internet.</li>
                            </ul>
                            <p>Are you looking for a custom android app? Then get in touch with Datage at <a href="mailto:hello@datage.in">hello@datage.in</a> to hire talented android app developers.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-12 mp-services"><span>Related Services</span>
                        <div className="container">
                        <div className="row">
                            <div className="col-md-4 black"><a href="https://datage.in/react-native-development-services/">React Native Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/python-development-services/">Python Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/reactjs-development-services/">ReactJS Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/angularjs-development-services/">AngularJS Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/django-oscar-development-services/">Django Oscar E-commerce Development</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/e-learning-software-development-services/">E-learning Software Development Services</a></div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        
                        &nbsp;
                        <div className="contact_container row justify-content-center">
                        <div className="col-md-12 col-lg-12">
                            <div className="contact_block row marl">
                            <div className="col-md-7 col-xs-12">
                                <Callout />
                            </div>
                            <div className="col-md-5 col-xs-12 col-sm-12">
                                <div className="contact_content">
                                <h1>Android App Development</h1>
                                <p>Our Developers has in-depth knowledge of an android system and app development technologies and they are improving their skills to give you best results. Our Developers will understand your idea and will help you at every stage of app development.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
                <div className="col-md-12 customer_reviews">
                <div className="container">
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Android
