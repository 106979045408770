import React from 'react'
import { Link } from 'react-router-dom';

import TestimonialDisplayer from './TestimonialDisplayer';

function WhyUs( ) {
    return (
        <div>
          <section class="why_choose custom_manage">
      <div class="choose_us">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <h2 class="heading ">Why Choose Us? 
                  </h2>
                  <div class="description_block">
                     <p>Being a self-established organization starting from scratch, we understand and relate to the kind of dreams and efforts, which goes into small startups to medium enterprises. We understand how important it is to stand out in this current crowded market and be successful in whatever line of  business we do. The importance of constantly competing with competitors to stay on top of the game. We at Datage, understand that every business has different requirement and requires a different approach.</p>
                  </div>
                  <Link to="/contact-us/">
                  <p class="technical">Give us a ping Or Leave a message Our technical team will reach out to you at the earliest.</p>
                 </Link>
               </div>
              <TestimonialDisplayer className="mt-4" />
            </div>
         </div>
      </div>
   </section>
            
        </div>
    )
}

export default WhyUs
