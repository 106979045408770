import React from 'react'
import Callout from 'components/Callout'

function WebDesign( ) {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="seo_banner" style={{width: '100%'}}>
                        <div className="service_banner row justify-content-center">
                        <div className="container">
                            <div className="col-md-12">
                            <h1 className="heading">Responsive Web Design Services</h1>
                            <div className="content">
                                <p>You might have visited a lot of websites which offer you an enhanced browsing experience with its aesthetically appealing layouts. These sites adjust themselves easily on a desktop or a mobile device. Ever wondered that how do these websites act dynamically? Well, it is <strong>Responsive Web Design</strong>, which makes it all happen. As compared to Adaptive web design which uses web technologies in a layered fashion to allow users to access content of the page.</p>
                            </div>
                            </div>
                        </div>
                        <div className="service_qualities row">
                            <div className="container">
                            <div className="desc">
                                <h2 className="heading text-center">Responsive Web Design uses the following technologies</h2>
                                <ul className="bullet_ul">
                                <li>Fluid, proportion based grids – This concept enables the web page elements to be sized in relative units such as – percentage rather than absolute units like pixels.</li>
                                <li>Flexible images – The size of the images is also in relative units like percentage which allows them not to come out of their containing elements.</li>
                                <li>HTML5 – Websites built using Responsive Web Design are written in HTML5 markup language. HTML5 is popular for structuring and presenting content on the internet.</li>
                                <li>CSS3 media queries – With various CSS (Cascade style sheets) media queries the width of the website can be easily varied in different devices. The website adjusts itself automatically in a mobile device or a PC.</li>
                                </ul>
                                <p>The combination of all these concepts in Responsive Web Design, make it possible to adapt the layout to the viewing environment.</p>
                                <p>To make it easier to understand we can say that Responsive Web Development is a technique to make web designs which provide the user with the best viewing experience. These web designs are quite interactive and offer an excellent UI/UX interface. Websites built using RWD provide user friendly navigation and as already discussed, they can be viewed in a wide range of devices (from desktop to mobile). Just as fluids take up the shape of the container they are placed in similarly websites built using Responsive Web Design take the shape of the device they are browsed in.</p>
                                <p>As mobile internet use accounts for more than half of the internet use hence Responsive Web Development has become a very popular technology in recent times. In fact, Google increases the rating of a website, if it has a mobile friendly design.</p>
                                <p>All popular website you come across with, are built using HTML5 and CSS3 media queries. CSS3 media queries form a very important part of RWD. So let us try to understand CSS3 more clearly.</p>
                                <div className="row justify-content-center text-center">
                                <h3 className="heading">CSS3 And Its Features</h3>
                                </div>
                                <p>In simple language CSS defines the visual style of web pages written in HTML5, XHTML, and XML etc. Web developers use CSS along with HTML and JavaScript to create highly engaging web pages. It separates the content from the presentation styles such as layouts, colour and fonts. This feature makes the content more accessible. This separation of content and format enables the web pages to be presented in different styles on-screen, in print, when read-out by a screen reader available on the website and on Braille based devices. It also allows the flexibility to multiple HTML pages to share the specific formatting by specifying the .css file. This reduces complexity and the need for repetition. It is because of CSS that RWD enables the web pages to adjust themselves to the size of the screen. The horizontal placement of elements was easy to control in the websites but the vertical placement was always quite difficult. Centring an element vertically required complex style rules. With the introduction of CSS this problem has been resolved.</p>
                                <p>An important feature of CSS is that it allows the current .css file to be overridden easily. Hence, changes can be easily made to a hundred of documents by simply editing a few lines in the CSS files. Inheritance is another feature of CSS which in a style sheet prevents certain properties from being declared again and again. This allows software developers to write less CSS and enables faster loading of web pages. A lot of money which goes into development cost is also saved with this feature. CSS also uses techniques such as CSS hacks to target all types of browsers.</p>
                                <p>CSS, over the years has gradually updated from CSS1, CSS2, and CSS2.1 to CSS3. Each time a new version is launched it has added features as compared to its previous version. Such an added feature in CSS3 is column-count module which allows implementation of layouts with multiple columns easier. At Micro Pyramid we ensure Responsive Web Development is done using HTML5 and CSS3.</p>
                                <div className="row justify-content-center text-center">
                                <h3 className="heading">CSS3 Media Queries</h3>
                                </div>
                                <p>CSS3 media queries are an extremely important technology in Responsive Web Development. The media queries in the CSS module allow content rendering to adapt to conditions such as screen resolution. (For eg – Smartphone screen Vs Computer screen)</p>
                                <div className="row justify-content-center text-center">
                                <h3 className="heading">CSS3 Pre-Processors</h3>
                                </div>
                                <p>CSS is written in dynamic style sheet languages, Saas and less. Less is inspired by Saas and is simply an extended form of CSS. (For eg- The curly brackets used while writing the syntax in CSS was removed from less) Newer Saas versions have also introduced CSS like syntaxes such as SCSS (Sassy CSS).</p>
                                <p>Both of these CSS pre-processors have many features, such as –</p>
                                <ul className="bullet_ul">
                                <li>They allow variables to be defined easily.</li>
                                <li>They allow nesting.</li>
                                <li>They allow efficient code repetition.</li>
                                </ul>
                                <div className="row justify-content-center text-center">
                                <h3 className="heading">CSS Frameworks (Bootstrap and Foundation)</h3>
                                </div>
                                <p>There are also some pre-defined libraries of CSS frameworks available which make styling web pages much easier. They offer readymade options for designing and layout. Two of such frameworks are Bootstrap and Foundation.</p>
                                <div className="row justify-content-center text-center">
                                <h3 className="heading">Advantages of RWD</h3>
                                </div>
                                <ul className="bullet_ul">
                                <li>Increases reach to a wide audience. Apart from the one’s using computers to the ones using mobiles and tablets.</li>
                                <li>Increases sales by increasing the reach.</li>
                                <li>Uses analytics tools such as Google analytics to handle multiple devices and responsive reporting.</li>
                                <li>Having a RWD site means Google will increase your rating as discussed in the start of the article. This means that your visibility in search engines will improve drastically.</li>
                                <li>Saves Mobile Development cost.</li>
                                <li>Saves the cost of site management.</li>
                                <li>Enhances users browsing experience.</li>
                                </ul>
                                <p>Contact us at <a href="mailto:hello@datage.in">hello@datage.in</a> to know more.</p>
                            </div>
                            <div className="col-md-12 mp-services"><span>Related Services</span>
                                <div className="container">
                                <div className="row">
                                    <div className="col-md-4 black"><a href="https://datage.in/amp-design-development-services/">AMP Design and Development Services</a></div>
                                    <div className="col-md-4 black"><a href="https://datage.in/bootstrap-web-development/">Bootstrap Web Development Services</a></div>
                                    <div className="col-md-4 black"><a href="https://datage.in/python-development-services/">Python Development Services</a></div>
                                    <div className="col-md-4 black"><a href="https://datage.in/aws-consulting-services/">AWS Consulting Services</a></div>
                                    <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website Maintenance Services</a></div>
                                </div>
                                </div>
                            </div>
                            
                            <div className="contact_container row justify-content-center">
                                <div className="col-md-12 col-lg-12">
                                <div className="contact_block row marl">
                                    <div className="col-md-7 col-xs-12">
                                    <Callout />
                                    </div>
                                    <div className="col-md-5 col-xs-12 col-sm-12">
                                    <div className="contact_content"><span className="jost">Responsive Web Design Services</span>
                                        <p>We design responsive web pages for all devices like Desktop, Tablet, mobile to render your website in a better way as per the design, content, images everything.</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                    </div>
                    </div>
                </div>
                <div className="col-md-12 customer_reviews">
                    <div className="container">
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default WebDesign
