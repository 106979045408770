import React from 'react'

function FaqPage( ) {
    return (
        <div data-spy="scroll">
         <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="row" style={{fontFamily: '"Muli",sans-serif'}}>
                        <div className="row faq_banner justify-content-center">
                        <h1><i className="fa fa-comments" />Frequently Asked Questions</h1>
                        </div>
                        <div className="container zero-padding">
                        <div className="row justify-content-center no-gutters">
                            <div className="col-lg-12 col-md-12">
                            <div className="faq-details row ">
                                <div className="blog-item"><span>When was Datage founded?</span>
                                <p>Datage was founded in 2012 by Ashwani Kumar Iraganaboina and it was incorporated on feb 7th 2014 as Datage Informatics Pvt Ltd, a private limited company under the Indian Companies Act, 1956.</p>
                                </div>
                                <div className="blog-item"><span>What is the team size?</span>
                                <p>Datage has 40+ Employees as of December 2018.</p>
                                </div>
                                <div className="blog-item"><span>What are the technologies you are good with?</span>
                                <p>Python, Django, Amazon Web Services, React Js, Angular, React Native, Salesforce, Firebase, Django Rest Framework, Ansible, andriod, Bootstrap, Docker, Kubernetes.</p>
                                </div>
                                <div className="blog-item"><span>Which countries do you operate in?</span>
                                <p>Datage is operating, USA, UK, UAE countries.</p>
                                </div>
                                <div className="blog-item"><span>Who are the bigger clients or what are the bigger projects you did work on?</span>
                                <p>Datage has worked with many clients and also providing support to them. Our bigger clients are Sechaba, Diego V Lozano, Brain, Sitaraman Lakshminarayanan.</p>
                                <p>Our bigger projects are Nowfinance, Refactored, Promusictutor, ParentOrbit, AutoDs tools.</p>
                                </div>
                                <div className="blog-item"><span>How quick you can start working for us?</span>
                                <p>As soon as getting a request from client, we'll start interaction with client, understand their requirements &amp; ideas, enhance it with our methodology and our team will start working on it.</p>
                                </div>
                                <div className="blog-item"><span>Where is your office located at?</span>
                                <p>The address of the registered office of Datage is as below.</p>
                                <p>#22, Salarpuria-I, Koramangala, Bengaluru, Karnataka - 560001.</p>
                                </div>
                                <div className="blog-item"><span>Which methodology we use?</span>
                                <p>We follow agile methodology to build secure, scalable and high-performance applications.</p>
                                </div>
                                <div className="blog-item"><span>Do you have SLA?</span>
                                <p>Yes, We've. After signing on SLA only, Development work will start on client requirements.</p>
                                </div>
                                <div className="blog-item"><span className="blog-title">Do you have NDA?</span>
                                <p>Yes, We've. After Signing on NDA only, We'll start working on POC's, other works based on client requirements</p>
                                </div>
                            </div>
                            </div>
                            {/* blog list ends here */}{/* pagination */}</div>
                        </div>
                    </div>
                    <div className="container">
                    </div>
                    </div>
                </div>
                </div>
            </div>
</div>
 </div>
    )
}

export default FaqPage
