import React from 'react'

function MobileSection( ) {
    return (
        <div>
            <section className="mobile_solutions custom_manage">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-md-6 mobile_responsive">
                        <img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/mobile_app_service_home.png" width="100%" alt="mobile_development" />
                    </div>
                    <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                        <h2 className="heading">Mobile App Development</h2>
                        <div className="description_block">
                        <p>Datage offers customized Mobile solutions based on client requirement and expectations. We build scalable and robust mobile applications, which work across platforms. </p>
                        <p>Leveraging latest development practices, techniques, and software like React Native we provide cost-effective solutions to all kind of business needs, from emerging organizations to large global enterprises we cater to all requirements and challenges.</p>
                        <ul>
                            <li>React Native</li>
                            <li>Android App Development</li>
                            <li>iOS App Development</li>
                            <li>Hybrid Apps (Ionic &amp; PhoneGap)</li>
                            <li>Flutter</li>
                        </ul>
                        </div>
                        <div className="read_more_app">
                        <a href="/react-native-development-services/" target="_blank"><strong>Read More</strong></a>
                        </div>
                    </div>
                    <div className="col-md-6 desktop_responsive">
                        <img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/mobile_app_service_home.png" width="100%" alt="mobile_development" />
                    </div>
                    </div>
                </div>
                </section>
        </div>
    )
}

export default MobileSection;
