import React from 'react'

function DevelopmentSection( ) {
    return (
        <div>
             <section className="web_development">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12">
            <h2 className="heading">Custom Software Development
            </h2>
            <div className="description_block">
              <p>At Datage, we are a diverse group of a custom software development team with huge experience in handling Real-time Web Applications, Mobile application APIs, Content Management Systems, e-Commerce and Single page applications.</p>
              <p>We are a proven team with immense coding skills in ReactJS, React Native, Laravel, NodeJS, Elastic Search, Python, Docker, Amazon Web Services, PostgreSQL, MySQL, MongoDB, SASS, Bootstrap and Micro-Services.</p>
              <p>
                <ul>
                  <li>SaaS and Cloud Computing</li>
                  <li>Wordpress, CRM &amp; ERP Systems</li>
                  <li>Mobile Applications</li>
                  <li>E-commerce Solutions</li>
                  <li>Fin-tech, Health-tech and Ed-Tech solutions</li>
                </ul>
              </p>
            </div>
            <div className="read_more_app"><a href="/python-development/" target="_blank"><strong>Read More</strong></a></div>
          </div>
          <div className="col-md-6">
            <img loading="lazy" src="/assets/img/new-images/development_service1_home.png" width="100%" alt="website_development" />
          </div>
        </div>
      </div>
    </section>
        </div>
    )
}

export default DevelopmentSection;
