import React from 'react'
import Callout from 'components/Callout'

function Docker( ) {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="seo_banner docker_banner row justify-content-center">
                        <div className="col-lg-10 col-md-12">
                        <div className="row">
                            <div className="col-md-5">
                            <div className="logo" style={{textAlign: 'center'}}><img src="https://d28k6hocvoxiuc.cloudfront.net/site/images/docker_service.png" /></div>
                            </div>
                            <div className="col-md-7 ">
                            <div className="content">
                                <h1>Docker Consulting &amp;<br />
                                Container services</h1>
                            </div>
                            <p align="center" className="para">Deploy Your Application Easily</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="row docker_container">
                        <div className="container">
                        <div className="docker_conatiner_block">
                            <div className="row">
                            <div className="col-md-4">
                                <div className="content">
                                <div className="desc"><strong>Containers:</strong>
                                    <p>Containers are the operating system level virtualization where the virtual layer runs as an application within one OS hence using the same kernel. There is no need to invest in extra servers and infrastructure to deploy the applications.</p>
                                    <p>These containers have their own process and network space, however the security isn’t as good as other operating system level virtualization technology such as OpenVZ.</p>
                                </div>
                                <div className="desc">
                                    <h2 style={{fontSize: '1.5rem', paddingBottom: 10}}>Docker Containers:</h2>
                                    <p>Docker is an open source Linux tool that can easily and efficiently create and manage containers. Docker can be easily integrated with different infrastructures like Google Cloud Platform, Microsoft Azure, Amazon Web Services, Kubernetes, Oracle Container Cloud Service, Puppet, Ansible, Chef, Jenkins, OpenStack, Salt and Vagrant.</p>
                                </div>
                                <div className="desc">
                                    <h2 style={{fontSize: '1.5rem', paddingBottom: 10}}>The services that our Docker experts offer.</h2>
                                    <ul>
                                    <li>Transforming your monolithic application into a modular containerized app.</li>
                                    <li>Manage your ever growing containers with powerful platforms like kubernetes, docker swarm.</li>
                                    <li>POC for your ideas.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="content">
                                <div className="desc"><strong style={{fontWeight: 500}}>Increase security, faster deployments </strong>
                                    <ul>
                                    <li>Using Docker application deployment is fast due to least amount of runtime requirements.</li>
                                    <li>Since all the dependencies are included into a single container which isn’t dependent on the host, the container can be migrated to other machines which are running Docker and deployed without any hassle.</li>
                                    <li>Docker images are lightweight and has minimal overhead, hence delivery and deployment of application containers takes minimal time.</li>
                                    <li>Due to versioning system, containers can be easily debugged, inspect difference between different versions, roll back to stable versions.</li>
                                    <li>Using remote repositories images can be shared. Docker provides a public repository which provides many images contributed by users.</li>
                                    <li>Unlike virtual machines, containers can be created or destroyed quickly and easily. Virtual Machines need to have full installations and more resources to run.</li>
                                    <li>Since containers are lightweight and require less computing resources, the host can run more containers simultaneously.</li>
                                    <li>Docker containers run on all popular Linux distributions, Windows operating systems, Mac OS X and also on infrastructures like virtual machines.</li>
                                    </ul>
                                </div>
                                <div className="desc">
                                    <h2 style={{fontSize: '1.5rem', paddingBottom: 5, paddingTop: 5}}>Why customer choose Datage for Docker consulting?</h2>
                                    <p>We rely on opensource, cutting edge technologies to build high performance and reliable applications. With our CI/CD pipelines, we deliver your applications faster.</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-12 mp-services"><span>Related Services</span>
                        <div className="container">
                        <div className="row">
                            <div className="col-md-4 black"><a href="https://datage.in/aws-consulting-services/">AWS Consulting Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/amazon-simple-storage-services/">Amazon S3 Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/amazon-notification-services/">Amazon SES Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/aws-consulting-partner/">AWS Consulting Partner</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/server-maintenance-services/">Server Maintenance Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website Maintenance Services</a></div>
                        </div>
                        </div>
                    </div>
                    
                    <div className="contact_container row justify-content-center">
                        <div className="col-md-12 col-lg-10">
                        <div className="contact_block row marl">
                            <div className="col-md-7 col-xs-12">
                            <Callout />
                            </div>
                            <div className="col-md-5 col-xs-12 col-sm-12">
                            <div className="contact_content"><span className="jost">Docker Consulting</span>
                                <p>With our specialized docker consulting group, build highly scalable apps with modular and independent services, having Docker which powers microservice architecture we can build robust, cost effective and reliable applications</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
                <div className="col-md-12 customer_reviews">
                <div className="container">
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default  Docker;
 