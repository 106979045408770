import React from 'react'
import { Link } from 'react-router-dom'

function Header( ) {
    return (
        <div>
          <div className="top_header">
            <div className="row no-gutters logo_menu sticky">
                <div className="col-md-12 header_privacy">
                <div className="menu_section_header about_us_menu">
                    <div className="container menu_list_section">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <a className="navbar-brand" href="/"><img loading="lazy" src="/assets/logo.png" alt="Software Development Company" /></a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        {/* <span class="navbar-toggler-icon">
                                
                            </span> */}
                        <i className="fas fa-bars" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-items active">
                            <Link className="nav-link" to="/" aria-selected="false">HOME</Link>
                            </li>
                            <li className="nav-items ">
                            <Link className="nav-link" to="/our-tech-stack/" aria-selected="false">TECH STACK</Link>
                            </li>
                            <li className="nav-item dropdown about_us2019 d-none">
                            <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLinkParent_9" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-selected="false">ABOUT US</a>
                            <div className="dropdown-menu about_us_menu columns-1" aria-labelledby="navbarDropdownMenuLinkParent_9">
                                <div className="row no-gutters">
                                <div className="col-md-12">
                                    <Link className="dropdown-item" to="/faqs/">FAQ's</Link>
                                </div>
                                <div className="col-md-12">
                                    <Link className="dropdown-item" to="/our-tech-stack/">OUR TECH STACK</Link>
                                </div>
                                </div>
                            </div>
                            </li>
                            <li className="nav-items services">
                            <a className="nav-link nav-link-services" href="#" aria-selected="false">SERVICES</a>
                            <div className="dev_services about_us_menu" style={{display: 'none'}}>
                                <div className="development_menu">
                                <i className="fa fa-arrow-right" />
                                <div className="row no-gutters">
                                    <div className="col-md-12 col-lg-4">
                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                        <span><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/custom__development.png" alt /></span>Development</a>
                                        <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                        <span> <img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/mobile--development.png" alt /></span>Mobile Development</a>
                                        <a className="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false"> 
                                        <span> <img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/design__development.png" alt /> </span>UI &amp; UX Design</a>
                                        {/* <a className="nav-link" id="v-pills-sales-tab" data-toggle="pill" href="#v-pills-sales" role="tab" aria-controls="v-pills-sales" aria-selected="false"> 
                                        <span> <img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/salesforce_m_logo.png" alt /> </span>Salesforce</a> */}
                                        {/* <a class="nav-link" id="v-pills-digital-marketing-tab" data-toggle="pill" href="#v-pills-digital-marketing" role="tab" aria-controls="v-pills-digital-marketing" aria-selected="false"> 
                                                        <span> <img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/digital_marketing.png" alt=""> </span>Digital Marketing</a> */}
                                        <a className="nav-link" id="v-pills-others-tab" data-toggle="pill" href="#v-pills-others" role="tab" aria-controls="v-pills-others" aria-selected="false"> 
                                        <span> <img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/other--development.png" alt /> </span>Other Services</a>
                                    </div>
                                    </div>
                                    <div className="col-md-12 col-lg-8" style={{borderLeft: '1px solid #ddecf9'}}>
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div className="tab-pane fade active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                        <div className="services_related">
                                            <Link to="/reactjs-development-services/">
                                            <span className="menu2"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/react.png" alt /></span>
                                            React
                                            </Link>
                                            <Link to="/#">
                                            <span className="menu2"><img loading="lazy" src="/assets/img/new-images/nodejs.png" alt /></span>
                                            Express
                                            </Link>
                                            <Link to="/#">
                                            <span className="menu2"><img loading="lazy" src="/assets/img/new-images/graphql.png" alt /></span>
                                            GraphQL
                                            </Link>
                                            <Link to="/python-development-services/">
                                            <span className="menu2"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/python_logo.png" alt /></span>
                                            Python</Link>
                                            <Link to="/django-development-services/">
                                            <span className="menu2"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/django_no-color.png" alt /></span>
                                            Django
                                            </Link>
                                            <Link to="/angularjs-development-services/">
                                            <span className="menu2"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/angular_red.png" alt /></span>
                                            Angular
                                            </Link>
                                            <Link to="/postgresql-administration-and-management/">
                                            <span className="menu2"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/postgresql_logo.png" alt />
                                            </span>
                                            Postgresql
                                            </Link>
                                            <Link to="/ecommerce-web-development-services/">
                                            <span><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/e-commerce_development.png" alt /></span>
                                            e-Commerce
                                            </Link>
                                            <Link to="/django-rest-framework-development-services/">
                                            <span className="menu2"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/django_rest.png" alt /></span>
                                            Django Rest
                                            </Link>
                                            <Link to="/mongodb-development-services/">
                                            <span><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/mango-dp.jpg" alt /></span>
                                            Mongo DB
                                            </Link>
                                            <Link to="/#">
                                            <span><img loading="lazy" src="/assets/img/new-images/laravel.png" alt /></span>
                                            Laravel/PHP
                                            </Link>
                                        </div>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                        <div className="services_related">
                                            <Link to="/android-app-development-services/">
                                            <span><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/android.png" alt /></span>
                                            Android</Link>
                                            <Link to="/react-native-development-services/">
                                            <span className="menu2"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/react.png" alt /></span>
                                            React Native</Link>
                                            
                                            <Link to="/react-native-development-services/">
                                            <span className="menu2"><img loading="lazy" src="/assets/img/new-images/ios.png" alt /></span>
                                            iOS</Link>
                                        </div>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                        <div className="services_related">
                                            <Link to="/responsive-web-design/">
                                            <span className="menu1"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/responsive_Design.png" alt /></span>
                                            Web Responsive</Link>
                                            <Link to="/bootstrap-web-development/">
                                            <span className="menu2"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/bootstrap.png" alt /></span>
                                            Bootstrap
                                            </Link>
                                            <Link to="/#">
                                            <span><img loading="lazy" src="/assets/img/new-images/figma.png" alt /></span>
                                            Figma
                                            </Link>
                                        </div>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-sales" role="tabpanel" aria-labelledby="v-pills-sales-tab">
                                        <div className="homemenu other_services_menu">
                                            <ul className="navbar-nav">
                                            <li className="nav-item">
                                                <div className="columns-1" aria-labelledby="navbarDropdownMenuLinkParent_3">
                                                <div className="col-md-12 no-padding">
                                                    <Link className="dropdown-item" to="/salesforce/crm-services/">Salesforce CRM</Link>
                                                    <Link className="dropdown-item" to="/salesforce/onboarding-solution/">Salesforce Onboarding</Link>
                                                    <Link className="dropdown-item" to="/salesforce/development-services/"> Salesforce Development</Link>
                                                    <Link className="dropdown-item" to="/salesforce/hire-developer/">Hire Salesforce Developer</Link>
                                                    <Link className="dropdown-item" to="/salesforce/admin-services/">Salesforce Admin Services</Link>
                                                    <Link className="dropdown-item" to="/salesforce/customization-services/">Salesforce Customization Services</Link>
                                                    <Link className="dropdown-item" to="/salesforcedustry-solutions/"> Salesforce Industry Solutions</Link>
                                                </div>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                                <div className="columns-1" aria-labelledby="navbarDropdownMenuLinkParent_3">
                                                <div className="col-md-12 no-padding">
                                                    <Link className="dropdown-item" to="/salesforce/managed-services/">Salesforce Managed Services</Link>
                                                    <Link className="dropdown-item" to="/salesforce/implementation-services/">Salesforce Implementation Services</Link>
                                                    <Link className="dropdown-item" to="/salesforcetegration-services/">Salesforce Integration Services</Link>
                                                    <Link className="dropdown-item" to="/salesforce/marketing-cloud-services/"> Salesforce Marketing Cloud Services</Link>
                                                    <Link className="dropdown-item" to="/salesforce/sales-cloud-services/">Salesforce Sales cloud Services</Link>
                                                    <Link className="dropdown-item" to="/salesforce/appexchange-development/">Salesforce Appexchange Development</Link>
                                                    <Link className="dropdown-item" to="/salesforce/consulting-services/">Salesforce Consulting</Link>
                                                </div>
                                                </div>
                                            </li>
                                            </ul>
                                        </div>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-digital-marketing" role="tabpanel" aria-labelledby="v-pills-digital-marketing-tab">
                                        <div className="homemenu other_services_menu">
                                            <ul className="navbar-nav">
                                            <li className="nav-item">
                                                <div className="columns-1" aria-labelledby="navbarDropdownMenuLinkParent_3">
                                                <div className="col-md-12 no-padding">
                                                    <Link className="dropdown-item" to="/search-engine-optimization/">Search Engine Optimization</Link>
                                                    <Link className="dropdown-item" to="/e-commerce-seo/">E-commerce SEO</Link>
                                                    <Link className="dropdown-item" to="/seo-resource-outsourcing/"> SEO resource OutSourcing</Link>
                                                    <Link className="dropdown-item" to="/landing-page-optimization/">Landing Page Optimization</Link>
                                                    <Link className="dropdown-item" to="/conversion-rate-optimization/">Conversion Rate Optimization</Link>
                                                    <Link className="dropdown-item" to="/multilingual-seo/">Multilingual SEO</Link>
                                                    <Link className="dropdown-item" to="/local-seo/"> Local SEO</Link>
                                                    <Link className="dropdown-item" to="/seo-content-development/">SEO Content Development</Link>
                                                    <Link className="dropdown-item" to="/seo-consulting/">SEO Consulting</Link>
                                                    <Link className="dropdown-item" to="/online-reputation-management/">Online Reputation Management</Link>
                                                </div>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                                <div className="columns-1" aria-labelledby="navbarDropdownMenuLinkParent_3">
                                                <div className="col-md-12 no-padding">
                                                    <Link className="dropdown-item" to="/online-advertising-services/">Online Advertising</Link>
                                                    <Link className="dropdown-item" to="/google-adwords/">Google Adwords</Link>
                                                    <Link className="dropdown-item" to="/shopping-campaigns-services/">Shopping Ads</Link>
                                                    <Link className="dropdown-item" to="/remarketing-campaigns-services/">Remarketing Ads</Link>
                                                    <Link className="dropdown-item" to="/facebook-advertising-services/">Facebook Advertising</Link>
                                                    <Link className="dropdown-item" to="/youtube-advertising-services/">YouTube Advertising</Link>
                                                    <Link className="dropdown-item" to="/twitter-advertising-services/">Twitter Advertising</Link>
                                                    <Link className="dropdown-item" to="/linkedin-advertising-services/">LinkedIn Advertising</Link>
                                                    <Link className="dropdown-item" to="stagram-advertising-services/">Instagram Advertising</Link>
                                                    <Link className="dropdown-item" to="/pinterest-advertising-services/">Pinterest Advertising</Link>
                                                    <Link className="dropdown-item" to="/google-analytics-consulting-services/">Google Analytics Consulting</Link>
                                                    <Link className="dropdown-item" to="/google-tag-manager-consulting-services/">Google Tag Manager Consulting</Link>
                                                </div>
                                                </div>
                                            </li>
                                            </ul>
                                        </div>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-others" role="tabpanel" aria-labelledby="v-pills-others-tab">
                                        <div className="homemenu other_services_menu">
                                            <ul className="navbar-nav">
                                            <li className="nav-item">
                                                <a>
                                                <span><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws.png" alt /></span>
                                                AWS CLOUD SERVICES
                                                </a>
                                                <div className="columns-1" aria-labelledby="navbarDropdownMenuLinkParent_3">
                                                <div className="row">
                                                    <div className="col-md-12 no-padding">
                                                    <Link className="dropdown-item" to="/aws-consulting-services/"> Aws Consulting Services</Link>
                                                    <Link className="dropdown-item" to="/amazon-simple-storage-services/">Simple Storage Services</Link>
                                                    <Link className="dropdown-item" to="/amazon-notification-services/">Simple Notification Services</Link>
                                                    <Link className="dropdown-item" to="/aws-consulting-partner/">AWS Consulting Partner</Link>
                                                    </div>
                                                </div>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                                <a>
                                                <span className="menu1"><img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/devops_development.png" alt /></span>
                                                DEVOPS
                                                </a>
                                                <div className=" columns-1" aria-labelledby="navbarDropdownMenuLinkParent_3">
                                                <div className="row">
                                                    <div className="col-md-12 no-padding">
                                                    <Link className="dropdown-item" to="/docker-consulting/">Docker</Link>
                                                    <Link className="dropdown-item" to="/server-maintenance-services/">Server Maintenance</Link>
                                                    <Link className="dropdown-item" to="/website-maintenance-services/">Website Maintenance Services</Link>
                                                    </div>
                                                </div>
                                                </div>
                                            </li>
                                            </ul>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </li>
                            <li className="nav-items">
                            <Link className="nav-link" to="/our-portfolio/" aria-selected="false"> WORK</Link>
                            </li>
                            <li className="nav-item dropdown about_us2019">
                            <Link className="nav-link" to="/contact-us/" aria-selected="false">
                                CONTACT US
                            </Link>
                            </li>
                        </ul>
                        </div>
                    </nav>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Header
