import React from 'react'

function OffersPage( ) {
    return (
        <div>
           <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                {/* <span class="conatact_us_icon">
                </span> */}
                {/*  <div class="overlay_contact"></div> */}
                {/*   <div class="contact_div_wrap">
            <div class="close_contact"></div>
            <div class="title_div">
            <div class="daimond"></div>
            <div class="daimond"></div>
            <div class="daimond"></div>
            <h3 class="title_contact"></h3>
            </div>

            <div class="col-md-12">
            <form id="contactform" name="contactform" method="post" action="/contact-us/">
            <div class="form_div row">
                <div class="col-md-6 form-group">
                    <input type="text" class="form-control" id="full_name" name="full_name" placeholder="Name">
                </div>
                <div class="col-md-6 form-group">
                    <input type="email" class="form-control" id="email" name="email" placeholder="Email Address">
                </div>
                <div class="form-group col-md-6">
                    <select class="form-control" name="enquery_type">
                    <option value="general">Request For Services</option>
                    <option value="partnership">Partnership Queries</option>
                    <option value="media">Media Queries</option>
                    <option value="general queries">General Queries</option>
                    <option value="feedback">Website Feedback</option>
                    <option value="salesforce-free-consulting" >Salesforce Free Consulting</option>
                    <option value="others">Others</option>
                    </select>
                </div>
                <div class="col-md-6 form-group">
                    <input type="text" class="form-control" id="phone" name="phone" placeholder="Phone Number">
                </div>
                <div class="col-md-6 form-group">
                    <input type="text" class="form-control" id="country" name="country" placeholder="Country">
                </div>
                <div class="col-md-12 form-group">
                    <textarea class="form-control" rows="10" id="message" name="message" placeholder="Your Message"></textarea>
                </div>
                <div  class="form-group col-md-12 form-field wow fadeInUp animated captcha" style="margin-bottom: 5px;">
                    <div class="button_div">
                    <div class="g-recaptcha" data-sitekey="6Lf49ysUAAAAAELS1eCQvy89-uO7HlTjX_PpYqMN"></div>
                    </div>
                </div>

                <div class="button_row row">
                <button class='submit_contact' type="submit"><i class='fa fa-paper-plane-o'></i>
                </button></div>
            </div>
            </form>
            </div>

            <div class="clearfix"></div>
                </div> */}
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="container">
                        <div className="row bloglist justify-content-center">
                        <div className="col-md-8 col-xl-8">
                            <div className="row justify-content-center aws_offers">
                            <div className="col-lg-6 col-xl-6 col-md-6">
                                <div className="offer-item one"><span className="offer-title">Free AWS Consulting Services</span>
                                <p>Datage, a AWS standard consulting partner expert in cloud migration, infrastructure and scaling architecture. Serving across verticals such as Fintech, Automobile, Learning Management System etc with DevOps and cloud computing as baseline.</p>
                                <a className="know_more" href="https://datage.in/aws-free-consulting-offers/">Know More about offer</a></div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-md-6">
                                <div className="offer-item two"><span className="offer-title">Free Salesforce Consulting services</span>
                                <p>Datage has certified salesforce developers with 5+ years of experience and providing 30 days free consultation on all our salesforce consulting services.</p>
                                <a className="know_more" href="https://datage.in/salesforce-onboarding-solution/">Know More about offer</a></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                    </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</div>
   )
}

export default OffersPage;
