import React from 'react'

function TechStackPage( ) {
    return (
     <div>
        <div className="scroller">
        <div className="scroller-inner">
            <div className="codrops-top clearfix" />
            <div className="content clearfix">
                {/* <span class="conatact_us_icon">
                </span> */}
                {/*  <div class="overlay_contact"></div> */}
                {/*   <div class="contact_div_wrap">
            <div class="close_contact"></div>
            <div class="title_div">
            <div class="daimond"></div>
            <div class="daimond"></div>
            <div class="daimond"></div>
            <h3 class="title_contact"></h3>
            </div>

            <div class="col-md-12">
            <form id="contactform" name="contactform" method="post" action="/contact-us/">
            <div class="form_div row">
                <div class="col-md-6 form-group">
                    <input type="text" class="form-control" id="full_name" name="full_name" placeholder="Name">
                </div>
                <div class="col-md-6 form-group">
                    <input type="email" class="form-control" id="email" name="email" placeholder="Email Address">
                </div>
                <div class="form-group col-md-6">
                    <select class="form-control" name="enquery_type">
                    <option value="general">Request For Services</option>
                    <option value="partnership">Partnership Queries</option>
                    <option value="media">Media Queries</option>
                    <option value="general queries">General Queries</option>
                    <option value="feedback">Website Feedback</option>
                    <option value="salesforce-free-consulting" >Salesforce Free Consulting</option>
                    <option value="others">Others</option>
                    </select>
                </div>
                <div class="col-md-6 form-group">
                    <input type="text" class="form-control" id="phone" name="phone" placeholder="Phone Number">
                </div>
                <div class="col-md-6 form-group">
                    <input type="text" class="form-control" id="country" name="country" placeholder="Country">
                </div>
                <div class="col-md-12 form-group">
                    <textarea class="form-control" rows="10" id="message" name="message" placeholder="Your Message"></textarea>
                </div>
                <div  class="form-group col-md-12 form-field wow fadeInUp animated captcha" style="margin-bottom: 5px;">
                    <div class="button_div">
                    <div class="g-recaptcha" data-sitekey="6Lf49ysUAAAAAELS1eCQvy89-uO7HlTjX_PpYqMN"></div>
                    </div>
                </div>

                <div class="button_row row">
                <button class='submit_contact' type="submit"><i class='fa fa-paper-plane-o'></i>
                </button></div>
            </div>
            </form>
            </div>

            <div class="clearfix"></div>
                </div> */}
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="container p-0">
                        <div className="row no-gutters">
                        <div className="col-md-9 m-auto service_page_container">
                            <h1 className="heading text-center mt-5 py-3">OUR TECH STACK</h1>
                            <h2 className="heading text-left">Tools and Technologies we use</h2>
                            <p>Here are the tools and technologies we use to deliver awesome products and services.</p>
                            <h2 className="mt-5 heading text-left">UI/UX</h2>
                            <p>SASS, HTML5/CSS3, Balsamiq, Figma, Illustrator, Invision, Zeplin, Bootstrap, Material Design, and Tailwind.</p>
                            <h2 className="heading text-left">Frontend</h2>
                            <p>ReactJs, Gatsby, GraphQL, Redux, Gulp, Webpack, Angular, VueJs, and JQuery.</p>
                            <h2 className="heading text-left">Backend</h2>
                            <p>NodeJs, Express, PHP, Wordpress, CodeIgniter, Laravel, GraphQL, Java-Spring, Golang, RoR and Python </p>
                            <h2 className="heading text-left">DataBase</h2>
                            <p>Postgresql, Mysql, Mongodb, Elasticsearch, Neo4J, GraphQL, Hasura, Redis, and SQL-lite.</p>
                            <h2 className="heading text-left">Mobile Apps</h2>
                            <p>React-Native, Flutter, Firebase, Android studio, Android app, XCode, iOS app, and Ionic.</p>
                            <h2 className="heading text-left">AWS</h2>
                            <p>EC2, RDS, Elastic Load Balancer, S3, Lambda, Cloudformation, Elastic Beanstalk, Route 53, IAM, Cloudfront, Redshift, Simple notification service, SES, SQS, Cloudwatch, and EFS.</p>
                            <h2 className="heading text-left">GCP</h2>
                            <p>Compute engine, kubernetes engine, Stackdriver, Cloud storage, Google container registry, App engine, IAM, Cloud build, Instance groups, Cloud sql, and Bigquery.</p>
                            <h2 className="heading text-left">DevOps</h2>
                            <p>AWS Cloud, Google Cloud, Github-Actions, Travis, Jenkins, Docker, NginX, Netlify, Firebase, Vercel and AWS Amplify</p>
                            <h2 className="heading text-left">Testing</h2>
                            <p>Selenium, Jest, Manual testing, Automation testing, Behavioral testing, and Mobile App testing.</p>
                            <h2 className="heading text-left">SEO</h2>
                            <p>Adwords, Google webmaster, Google tag manager, Mixpanel, Amplitude, and Google analytics.</p>
                            <h2 className="heading text-left">Others</h2>
                            <p>Redis, Memecached, Docker, Nifi, Git, VS-Code, Selenium, Kvm, Qemu, Hasura, Graphite, D3js, Jupyter, Tornado, Zeromq, Haproxy, Webrtc, Websockets, Openresty, istio, Auth0, and Kubernetes</p>
                            <p>REST Api, SOAP api, Micro-Services, Raspberry PI, GitLab-Ci, Landscape.io, Website automation, Trello, Asana, Github, Gitlab, Bitbucket, Sentry, Rocket chat, XMPP, and Salesforce CRM.</p>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                    </div>
                    </div>
                </div>
                <div className="col-md-12 customer_reviews">
                    <div className="container">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    )
}

export default TechStackPage
