import React from 'react'
import { Link } from 'react-router-dom'

function HeadingMenu( ) {
    return (
        <div>
        <div className="heading-menu">
            <div className="container">
            <div className="mp_banner">
                <div className="col-md-12 banner_tech_list">
                <div className="row">
                    <div className="col-md-7 service_tech">
                    <ul>
                        <li>
                            <Link to="#">React</Link>, <Link to="#">Express</Link>, <Link to="#">GraphQL</Link>
                        </li>
                        <li>
                            <Link to="#">Wordpress</Link>, <Link to="#">Laravel</Link>, <Link to="/python-development-services/">Python</Link>
                        </li>
                        <li>
                            <Link to="/aws-consulting-partner/">Amazon Web Services</Link>
                        </li>
                        <li>
                            <Link to="/react-native-development-services/">Mobile Apps</Link>, <Link to="#">DevOps</Link>
                        </li>
                    </ul>
                    </div>
                    <div className="col-md-5 pl-5">
                    <div className="consult_partner">
                        <span className="jost">Your Trusted Partner</span>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-lg-12 m-auto text-center">
                {/* <span class="slogan">One stop tech shop to develop end-to-end <a href="/django-development-services/" target="_blank">custom web applications</a>,<a href="/react-native-development-services/" target="_blank"> mobile applications</a>, <a href="/aws-consulting-services/" target="_blank">devops</a><a href="/salesforce/consulting-services/ " target="_blank"> and salesforce</a> for StartUps and SME.</span> */}
                <div className="banner_contact jost">
                    <Link to="/contact-us/"><i className="fas fa-phone-alt" /> Let's Talk And Get Started <i className="fas fa-angle-right" /></Link>
                </div>
                </div>
            </div>
            {/* bottom_header starts here */}
            <div className="mt-3 bottom_header ">
                <div className>
                <div className="row justify-content-center no-gutters">
                    <div className="container">
                    <div className="row no-gutters">
                        {/*   <div class="panel">
                            <h2>Technologies we love</h2>
                        </div> */}
                        <div className="col-md-12 col-lg-12">
                        <div className="  associated_logos">
                            <div className="item" style={{marginTop: 12}}>
                            <Link to="/aws-consulting-partner/" style={{display: 'block'}}>
                                <span><img loading="lazy" alt="Standard Consulting Partner" src="/assets/img/new-images/aws_home.png" /></span>
                                <p>Cloud Consulting Partner</p>
                            </Link>
                            </div>
                            <div className="item border-left border-right">
                            <Link to="/reactjs-development-services/" >
                                <span><img loading="lazy" src="/assets/img/new-images/mern.png" alt="python logo" /></span>
                                <p>Web Applications</p>
                            </Link>
                            </div>
                            <div className="item border-left border-right">
                            <Link to="/responsive-web-design/">
                                <span><img loading="lazy" alt="Member of Django" src="/assets/img/new-images/lamp.png" style={{maxWidth: '45%'}} /></span>
                                <p>Websites and UI/UX</p>
                            </Link>
                            </div>
                            <div className="item border-left border-right">
                            <Link to="/react-native-development-services/">
                                <span><img loading="lazy" alt="React Native" src="/assets/img/new-images/apps.png" style={{maxWidth: '45%'}} /></span>
                                <p>Mobile Apps</p>
                            </Link>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row justify-content-center"><div className="col-md-12 col-sm-10 col-xs-12 text-center">
                    <h2 className="heading text-center">Custom Software Development Services</h2>
                    <p className="text-center">Our custom software development services help you to unlock innovation and serve your business better with the latest technology according to your product's needs. We strive to provide robust, comprehensive, and efficient software services at your doorstep.</p></div></div>
                </div>
            </div>
            {/* bottom_header ends here */}
            </div>
      </div>
</div>
   )
}

export default HeadingMenu
