import React from 'react'

import Testimonials from './Testimonials';

function TestimonialDisplayer( ) {

    return (
        <div className="mt-5">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <section class="testnomials">
                     <div class="">
                        <h2 class="heading">Testimonials</h2>
                        <div class="row marl no-gutters justify-content-center">
                            <Testimonials />
                        </div>
                     </div>
                     <div>
                     <i class="fa fa-quote-right"></i>
                     </div>
                     
                  </section>
               </div>
            
        </div>
    )
}

export default TestimonialDisplayer
