import React from 'react'
import Callout from 'components/Callout'

function Oscar( ) {
    return (
    <div>
      <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="row mp_django_oscar">
                        <div className="django_oscar_banner">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-8">
                                <h2 className="title">Django Oscar Development Company</h2>
                                <p className="text-center">Datage is a leading Django Oscar Development Company and provides offshore development services.</p>
                            </div>
                            <div className="col-md-4 oscar_image"><img alt="Django Oscar" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/django_oscar.png" title="Django Oscar" /></div>
                            </div>
                        </div>
                        </div>
                        <div className="row justify-content-center">
                        <div className="container">
                            <div className="django_oscar_content row">
                            <h1 className="heading">Django Oscar Development Services</h1>
                            <p>Django Oscar Development Services helps the eCommerce businesses. Django Oscar development makes payment methods easily and simple.</p>
                            <div className="col-md-6 col-sm-12">
                                <ul>
                                <li>Oscar is an e-commerce platform for building domain-driven applications</li>
                                <li>E-commerce websites using Django Oscar are Namhah, chocolate box, TATA CLiQ etc.</li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <ul>
                                <li>Django is an open source framework written in python strongly supports e-commerce development.</li>
                                <li>Using Django Oscar user interface we can built large and small e-commerce websites.</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="row django_features justify-content-center">
                        <div className="container">
                            <div className="row no-gutters justify-content-center">
                            <h2 className="heading">Features of Django-oscar</h2>
                            </div>
                            <p>Django Oscar can handle any business requirements and solve complex problems like integrating payment methods and payment processing quickly and efficiently.</p>
                            <div className="row">{/* feature_block starts here */}
                            <div className="col-md-4 col-sm-6">
                                <div className="feature_block">
                                <div className="feature_content_image">
                                    <div className="feature_image"><img alt="Product Search" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/ec_search.png" /></div>
                                    <div className="desc_position">Giving the flexibility to search the products they are interested in. Using our well-designed dashboard you</div>
                                </div>
                                <p>Product Search</p>
                                </div>
                            </div>
                            {/* feature_block ends here */}{/* feature_block starts here */}
                            <div className="col-md-4 col-sm-6">
                                <div className="feature_block">
                                <div className="feature_content_image">
                                    <div className="feature_image"><img alt="Product Details" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/ec_pd.png" /></div>
                                    <div className="desc_position">It is important to display entire product information including stock information, ratings.</div>
                                </div>
                                <p>Product Details</p>
                                </div>
                            </div>
                            {/* feature_block ends here */}{/* feature_block starts here */}
                            <div className="col-md-4 col-sm-6">
                                <div className="feature_block">
                                <div className="feature_content_image">
                                    <div className="feature_image"><img alt="Product Filtering" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/ec_filter.png" /></div>
                                    <div className="desc_position">Providing flexibility to filter products based on price, category, rating is essential for any e-commerce website</div>
                                </div>
                                <p>Product Filtering</p>
                                </div>
                            </div>
                            {/* feature_block ends here */}{/* feature_block starts here */}
                            <div className="col-md-4 col-sm-6">
                                <div className="feature_block">
                                <div className="feature_content_image">
                                    <div className="feature_image"><img alt="Multiple Payment Methods" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/ec_pay.png" /></div>
                                    <div className="desc_position">Giving multiple payment methods like Pay with Paypal, Stripe, Pay using Credit Card makes easier for user to complete their purchase.</div>
                                </div>
                                <p>Multiple Payment Methods</p>
                                </div>
                            </div>
                            {/* feature_block ends here */}{/* feature_block starts here */}
                            <div className="col-md-4 col-sm-6">
                                <div className="feature_block">
                                <div className="feature_content_image">
                                    <div className="feature_image"><img alt="Managing Content" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/ec_dash.png" /></div>
                                    <div className="desc_position">Using our well-designed dashboard you can easily update pages, categories, products. Like Dashboard</div>
                                </div>
                                <p>Managing Your Content</p>
                                </div>
                            </div>
                            {/* feature_block ends here */}{/* feature_block starts here */}
                            <div className="col-md-4 col-sm-6">
                                <div className="feature_block">
                                <div className="feature_content_image">
                                    <div className="feature_image"><img alt="Product review and Ratings" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/ec_pd_review.png" /></div>
                                    <div className="desc_position">Giving the flexibility to search the products they are interested in. Using our well-designed dashboard you</div>
                                </div>
                                <p>Product Reviews and Ratings</p>
                                </div>
                            </div>
                            {/* feature_block ends here */}</div>
                        </div>
                        </div>
                        <div className="row oscar_testing_block justify-content-center">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6">
                                <ul>
                                <li>It can handle any product type. This includes downloadable products, subscriptions etc.</li>
                                <li>It provides an extensive catalogue support. In fact, this framework is used by websites that have over 20 million products.</li>
                                <li>Provides the option of multiple fulfilment partners for a single product.</li>
                                <li>Diverse range of merchandising blocks for promotion of products throughout your website.</li>
                                <li>It has a comprehensive dashboard that replaces the Django admin completely.</li>
                                <li>As already stated above, it provides multiple payment options for a single product</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul>
                                <li>It works on Postgres which protects data integrity more than MySQL</li>
                                <li>It can be used by website having more than 20 million products</li>
                                <li>It provides a modern design that is built using LESS and Bootstrap</li>
                                <li>There is no constraint on design. You can even use your own template.</li>
                                <li>Provides mobile friendly default templates</li>
                                <li>It provides support for complex products handling. This includes split payments orders, multi-batch shipping, and order status pipelines.</li>
                                </ul>
                            </div>
                            </div>
                            <p>If you are Looking for Django Oscar Development company then you have landed on right place. Contact our <a href="https://datage.in/django-development-services/">django development company</a> at <a href="mailto:hello@datage.in">hello@datage.in</a> for Django oscar development Services and other technologies according to your necessity</p>
                        </div>
                        </div>
                        <div className="container">
                        
                        <div className="row marl">
                            <div className="col-md-12 mp-services">
                            <h2 className="heading text-center">Related Services</h2>
                            <div className="container">
                                <div className="row">
                                <div className="col-md-4 black"><a href="https://datage.in/python-development-services/">Python Development Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/reactjs-development-services/">ReactJS Development Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/angularjs-development-services/">AngularJS Development Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/django-development-services/">Django Development Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/e-learning-software-development-services/">E-learning Software Development Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website Maintenance Services</a></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="container">
                        
                        <div className="contact_container row justify-content-center">
                            <div className="col-md-12 col-lg-12">
                            <div className="contact_block row marl">
                                <div className="col-md-7 col-xs-12">
                                <Callout />
                                </div>
                                <div className="col-md-5 col-xs-12 col-sm-12">
                                <div className="contact_content">
                                    <h2 className="heading">Django oscar Development Services</h2>
                                    <p>We offer development of e-stores, coupon and shipping websites for a variety of product types, categories, including consumer goods, digital assets, downloadable products.</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
                <div className="col-md-12 customer_reviews">
                <div className="container">
                </div>
                </div>
            </div>
        </div>
     </div>
    )
}

export default Oscar
