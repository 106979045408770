import React from 'react'

function WebinarPage() {
    return (
    <div>
        <div className="scroller-inner">
        <div className="codrops-top clearfix" />
        <div className="content clearfix">
            <div id="blog">
            <div className="row marl">
                {/* <span class="conatact_us_icon">
            </span> */}
                {/*  <div class="overlay_contact"></div> */}
                {/*   <div class="contact_div_wrap">
        <div class="close_contact"></div>
        <div class="title_div">
        <div class="daimond"></div>
        <div class="daimond"></div>
        <div class="daimond"></div>
        <h3 class="title_contact"></h3>
        </div>

        <div class="col-md-12">
        <form id="contactform" name="contactform" method="post" action="/contact-us/">
        <div class="form_div row">
            <div class="col-md-6 form-group">
                <input type="text" class="form-control" id="full_name" name="full_name" placeholder="Name">
            </div>
            <div class="col-md-6 form-group">
                <input type="email" class="form-control" id="email" name="email" placeholder="Email Address">
            </div>
            <div class="form-group col-md-6">
                <select class="form-control" name="enquery_type">
                <option value="general">Request For Services</option>
                <option value="partnership">Partnership Queries</option>
                <option value="media">Media Queries</option>
                <option value="general queries">General Queries</option>
                <option value="feedback">Website Feedback</option>
                <option value="salesforce-free-consulting" >Salesforce Free Consulting</option>
                <option value="others">Others</option>
                </select>
            </div>
            <div class="col-md-6 form-group">
                <input type="text" class="form-control" id="phone" name="phone" placeholder="Phone Number">
            </div>
            <div class="col-md-6 form-group">
                <input type="text" class="form-control" id="country" name="country" placeholder="Country">
            </div>
            <div class="col-md-12 form-group">
                <textarea class="form-control" rows="10" id="message" name="message" placeholder="Your Message"></textarea>
            </div>
            <div  class="form-group col-md-12 form-field wow fadeInUp animated captcha" style="margin-bottom: 5px;">
                <div class="button_div">
                <div class="g-recaptcha" data-sitekey="6Lf49ysUAAAAAELS1eCQvy89-uO7HlTjX_PpYqMN"></div>
                </div>
            </div>

            <div class="button_row row">
            <button class='submit_contact' type="submit"><i class='fa fa-paper-plane-o'></i>
            </button></div>
        </div>
        </form>
        </div>

        <div class="clearfix"></div>
            </div> */}
                <div id="container_wrapper blog_c_wrapper webinar_wrapper" style={{width: '100% !important'}}>
                <div className="blog_banner_wrap">
                    <div className="blog-categories-archives">
                    <h1 className="blog-cat-archives text-center non-category-blog heading">Free Live Webinars On Django, AWS</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row bloglist ">
                    <div className="col-md-12">
                        <div className="row justify-content-center ">
                        <div className="col-md-12">
                            <div className="blog-container row webinar-container">
                            </div>
                            {/* blog list ends here */}
                            {/* pagination */}
                            <div className="pagination_mp row">
                            <nav>
                                <ul className="pagination">
                                <li className="active"><a href="/webinars/1/">1</a></li>
                                </ul>
                            </nav>
                            </div>
                        </div>
                        {/*/ paginantion */}
                        </div>
                        {/* talk shop */}
                        <div className="container">
                        <div className="talkshop">
                            <h4 className="talkshop_content">Need any Help in your Project?<span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></h4>
                        </div>
                        </div>
                        {/*/ talk shop */} 
                        <div className="subscribe-blog-container">
                        <div className="container ">
                            <div id="subscribe">
                            <i className="fa fa-envelope-o subscribe_i" />
                            <h3>Subscribe To our <span>news letter</span></h3>
                            <div className="text">
                                <div>Subscribe and Stay Updated about our Webinars, news and articles on Django, Python, Machine Learning, Amazon Web Services, DevOps, Salesforce, ReactJS, AngularJS, React Native.</div>
                                <div><sub style={{fontSize: 21}}>*</sub>We don't provide your email contact details to any third parties</div>
                            </div>
                            <form id="subscribeform" name="subscribeform" method="post" action="/subscribe/">
                                <div className="form_fiv">
                                <div className="input-group subscribe_input">
                                    <input type="email" className="form-control subscribe_email" name="email" placeholder="Email Id" aria-describedby="basic-addon2" />
                                    <input type="hidden" name="is_blog" id="is_blog" defaultValue="True" />
                                    <input type="hidden" name="is_category" id="is_category" defaultValue />
                                    <span className="input-group-addon" id="basic-addon2"><button type="submit">Subscribe !</button></span>
                                </div>
                                <div className="input-group subscribe_input"><span id="subscribe-email" /></div>
                                </div>
                            </form>
                            </div>
                        </div>  
                        </div>
                    </div>
                    {/* blog row ends here */}
                    </div>
                </div>
                {/*/ container wrapper */}
                {/* footer links starts here */}
                {/* /block-posts */}
                <div className="col-sm-hidden col-md-1" />
                <div className="col-sm-12 col-md-3 blog-colors" id="right-sidebar">
                    <div className="row marl">
                    <div className="col-xs-12 search-padding">
                    </div>
                    <div className="clearfix" />
                    </div>
                    {/* / widget widget-text */}
                </div>
                {/* /row */}
                </div>
                {/* / right-sidebar */}
            </div>
            {/* blog list ends here */}
            </div>
        </div>
    </div>
</div>
    )
}

export default WebinarPage
