import React from 'react'
import Callout from 'components/Callout'

function Django( ) {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="service_banner django_service_page row justify-content-center">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <h1 className="heading text-uppercase text-underline">Django Development Services</h1>
                            <div className="text-center"><span className="heading">We build powerful Web applications with Django.</span></div>
                            <div className="col-md-10 text-left no-padding-web"><strong>Let's Bring your Idea to Reality.</strong></div>
                            <div className="content">
                                <p>With over 40+ projects our delivery system has spread out into diverse platforms and product in context serving globally in leading sectors of Financial services, E-commerce, Travel, Information &amp; Education industries. Hire our Django development company to outsource your software project for&nbsp;enterprise - level solutions.&nbsp;</p>
                            </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-center"><img alt="Agile Methodology" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/django_expertise_list.png" width="70%" /></div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="col-md-12">
                        <div className="row industries no-gutters python_service_devloment">
                            <div className="col-md-12 col-lg-12">
                            <h5 className="heading text-center">Areas of Expertise</h5>
                            <p>With a formidable approach and product in context we have built diverse websites appealing to Travel, E-commerce, Music, E-learning, Education, Job portals and Real Estate. These websites also appeal to a sense of comfort, art, beauty, wonderment and Exploration.</p>
                            </div>
                            <div className="col-md-12 top_heading">
                            <p>We specialized in Django and <a href="https://datage.in/python-development-services/">Python Development</a>. We build both web &amp; <a href="https://datage.in/react-native-development-services/">Mobile Applications</a> for all sizes of organisations. Our experts will help you on identifying best technology stack for you. So, that you can focus on growing your own business. We assure you high-quality work from our team as we are following agile development process. As a leading Django Development company, We are expertise in many technologies. Some of them are listed below</p>
                            </div>
                        </div>
                        </div>
                        <div className="service_qualities row no-gutters justify-content-center">{/* service_quality starts here */}
                        <div className="col-lg-12 col-md-12">
                            <div className="row python_service_devloment justify-content-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="col-md-12 col-sm-12 property" style={{boxShadow: 'inset 5px 4px 60px 0px rgba(45,152,172,0.2)', padding: '10px 10px 0px'}}>
                                <div className="row no-gutters">
                                    <div className="col-md-2 text-left">
                                    <div className="icon"><img alt="Agile Methodology" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/agile.png" /></div>
                                    </div>
                                    <div className="col-md-10 text-left"><strong>Agile Methodology</strong></div>
                                </div>
                                <div className="text-left content">
                                    <p>Business people and developers work together to continuous delivery of Quality software.</p>
                                </div>
                                </div>
                                <div className="col-md-12 col-sm-12 property" style={{boxShadow: 'inset 5px 4px 60px 0px rgba(45,152,172,0.2)', padding: '10px 10px 0px', marginBottom: 15}}>
                                <div className="row no-gutters">
                                    <div className="col-md-2 text-left">
                                    <div className="icon"><img alt="Agile Methodology" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/dedicated_team.png" /></div>
                                    </div>
                                    <div className="col-md-10 text-left"><strong>Dedicated Teams</strong></div>
                                    <div className="text-left">
                                    <p>Business people and developers work together to continuous delivery of Quality software.</p>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-12 col-sm-12 property" style={{boxShadow: 'inset 5px 4px 60px 0px rgba(45,152,172,0.2)', padding: '10px 10px 0px'}}>
                                <div className="row no-gutters">
                                    <div className="col-md-2 text-left">
                                    <div className="icon"><img alt="Quality Assurances" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/quality_testing.png" /></div>
                                    </div>
                                    <div className="col-md-10 text-left"><strong>Quality Assurance</strong></div>
                                    <div className="text-left">
                                    <p>We use continuous integrations(GitLab-CI, Jenkins, Travis-CI), selenium, unit tests and quality metrics are our tools in order to provide a best <a href="https://datage.in/software-testing-services/">QA Service.</a></p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="service_advantages row justify-content-center">
                                <div className="col-md-12 text-center">
                                    <h2 className="heading" style={{marginTop: 0}}>Our django development company take advantage of these technologies</h2>
                                    <p>As a leading a Django Development Company, we are expertise in all the major technologies.</p>
                                </div>
                                <div className="technologies_grid" style={{padding: 0}}>
                                    <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-2 col-sm-3 col-xs-4 skill"><img alt="Django Rest Framework" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/rest.png" title="Django Rest Framework" /></div>
                                    <div className="col-lg-4 col-md-2 col-sm-3 col-xs-4 skill"><img alt="Django Oscar" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/django_oscar.png" title="Django Oscar" /></div>
                                    <div className="col-lg-4 col-md-2 col-sm-3 col-xs-4 skill"><img alt="Celery" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/celery.png" style={{maxWidth: '70%'}} title="Celery" /></div>
                                    <div className="col-lg-4 col-md-2 col-sm-3 col-xs-4 skill"><img alt="Django CMS" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/dj_cms.png" style={{maxWidth: '70%'}} title="Django CMS" /></div>
                                    <div className="col-lg-4 col-md-2 col-sm-3 col-xs-4 skill"><img alt="Haystack" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/haystack.png" title="Haystack" /></div>
                                    <div className="col-lg-4 col-md-2 col-sm-3 col-xs-4 skill"><img alt="Selenium" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/selenium.png" style={{maxWidth: '70%'}} title="Selenium" /></div>
                                    <div className="col-lg-4 col-md-2 col-sm-3 col-xs-4 skill"><img alt="Memcached" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/memcached.png" title="Memcached" /></div>
                                    <div className="col-lg-4 col-md-2 col-sm-3 col-xs-4 skill"><img alt="Django Debug Toolbar" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/djdt.png" title="Django Debug Toolbar" /></div>
                                    <div className="col-lg-4 col-md-2 col-sm-3 col-xs-4 skill"><img alt="Redis" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/redis.png" title="Redis" /></div>
                                    <div className="col-lg-4 col-md-2 col-sm-3 col-xs-4 skill"><img alt="Elastic" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new/elastic.png" title="Elastic" /></div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        {/* service_quality ends here */}</div>
                        <div className="service_advantages row justify-content-center">
                        <div className="col-md-12 col-sm-12 col-xs-12 django_expert">
                            <h2 className="heading">Our dedicated Django developers team can easily do</h2>
                            <p>The developers present in our Django development company can easily do the listed things below</p>
                            <ul className="django_expert_list">
                            <li>RESTFull API development with <a href="https://datage.in/django-rest-framework-development-services/" style={{color: '#337ab7'}}>Django Rest Framework</a>.</li>
                            <li>e-commerce application development using <a href="https://datage.in/django-oscar-ecommerce-development-services/" style={{color: '#337ab7'}}>django-oscar</a>.</li>
                            <li>Payment gateway integrations PayPal, Stripe, PayU.</li>
                            <li>Social Authentications: Facebook, google, Twitter, Github.</li>
                            <li>3rd party API integrations.</li>
                            <li>Search implementation using elasticsearch, solr.</li>
                            <li>scheduled tasks using celery.</li>
                            <li>Django Unit tests.</li>
                            <li>Databases: <a href="https://datage.in/postgresql-administration-and-management/" style={{color: '#337ab7'}}>PostgreSQL</a>, <a href="https://datage.in/mongodb-development-services/" style={{color: '#337ab7'}}>MongoDB</a>, Mysql, MariaDB.</li>
                            <li>Functional tests using python-selenium.</li>
                            <li>CI &amp; CD using Travis, Jenkins, circle-ci, GitLab-CI.</li>
                            <li>Performance tuning.</li>
                            <li>Load testing with JMeter, Locust.</li>
                            <li>Hosting and maintaining django project.</li>
                            <li><a href="https://datage.in/search-engine-optimization/" style={{color: '#337ab7'}}>SEO</a> to increase site ranking.</li>
                            <li>And your dream ideas.</li>
                            </ul>
                        </div>
                        </div>
                        <div className="top_heading row justify-content-center">
                        <div className="col-md-12 col-xs-12"><span className="text-center heading">Datage for Django Development Services, USA, India</span>
                            <div className="content">
                            <p>At Datage, We have a team of Django developers where we focus on bringing your ideas to reality. We have more than 6 years of experience in successful execution of Django development projects. We have proven track record in achieving client goals and continuous providing extensive Django development services. In Datage you can outsource Django developers who have good knowledge in Django development. Hire Django developers from Datage to get your web application online.&nbsp;Contact our django development company now to hire django developers and start delivering high quality software to customers.</p>
                            <p>Want to bring your dreams to reality, Contact us now at <a href="mailto:hello@datage.in">hello@datage.in</a></p>
                            </div>
                        </div>
                        </div>
                        
                        <div className="col-md-12 mp-services"><span>Related Services</span>
                        <div className="container">
                            <div className="row">
                            <div className="col-md-4 black"><a href="https://datage.in/python-development-services/">Python Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/reactjs-development-services/">ReactJS Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/angularjs-development-services/">AngularJS Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/django-oscar-ecommerce-development-services/">Django-oscar E-commerce Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/e-learning-software-development-services/">E-learning Software Development Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website maintenance Services</a></div>
                            </div>
                        </div>
                        </div>
                        <div className="container">
                        <div className="contact_container row justify-content-center">
                            <div className="col-md-12 col-lg-12">
                            <div className="contact_block row marl no-gutters" style={{padding: 20}}>
                                <div className="col-md-7 col-xs-12">
                                <Callout />
                                </div>
                                <div className="col-md-5 col-xs-12 col-sm-12">
                                <div className="contact_content"><span className="jost">Django Development Services.</span>
                                    <p>Django as a framework has enabled web development to be simple, fast and practical with the output being aesthetically appealing. It has made possible development of high quality web apps in minimum time and with minimal efforts. It would be appropriate to say, that Django framework has changed the face of web development.</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
                <div className="col-md-12 customer_reviews">
                <div className="container">
                </div>
                </div>
            </div>
      </div>
    </div>
    )
}

export default Django
