import React from 'react'

function ConsultingPartner( ) {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="col-md-12 aws_services_devlopment">
                        <div className="banner">
                        <div className="container">
                            <div className="banner_content">
                            <div className="row aws_services_images">
                                <div className="col-md-9">
                                <h1>AWS Consulting Partner Company India</h1>
                                <p>We feel proud to be part of AWS Administrative, Development, <a href="https://datage.in/aws-consulting-services/">Consulting services</a> and for achieving AWS Standard Consulting partner with our certified sysops and devops professionals. Our certified professionals with our proven methodology can build your high quality web, mobile, game applications with maximized ROI.</p>
                                </div>
                                <div className="col-md-3 aws_image"><img alt="AWS Healthcare Logo" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws-standard-consulting-partner.jpg" /></div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="container mp_aws_services">
                        <div className="row">
                            <div className="col-md-4">
                            <div className="aws_items">
                                <ul>
                                <li className="service_role">Certified AWS Professionals</li>
                                <li className="service_manage">Multi Cloud Experience</li>
                                <li>24 / 7 / 365 Professional Support</li>
                                <li className="service_role">Quality, security, performance</li>
                                </ul>
                            </div>
                            </div>
                            <div className="col-md-4">
                            <div className="aws_mp_addition"><img alt=" AWS Consulting Parner" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/aws_service_development1.png" /></div>
                            </div>
                            <div className="col-md-4">
                            <div className="aws_item">
                                <ul>
                                <li className="service_onsite">High Availability &amp; Disaster Management</li>
                                <li className="service_advice">Enterprise security &amp; quality</li>
                                <li>Maximized ROI</li>
                                <li className="service_onsite">Reduced Risk And Maxmized Application</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="row aws_process">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-5">
                                <div className="aws_process_content">
                                <h2><i className="fa fa-check" />We respects, listen to your ideas</h2>
                                <h2><i className="fa fa-check" />Add our experts</h2>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="aws_process_content">
                                <h2><i className="fa fa-check" />We Enhance your ideas with our methodology</h2>
                                <h2><i className="fa fa-check" />Our Team Stay Engaged</h2>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="container aws_development_content"><span>What's so special about Amazon Web Services?</span>
                        <p>Amazon Web Services is a collection of remote computing services that together make up a cloud computing platform, which aims to take the headache out of traditional hosting, storage, computing solutions. AWS provides all types of services in one place, whuch make easy to developers to build highly scalable, secure applications</p>
                        </div>
                        <div className="row aws_help_service">
                        <div className="container">
                            <div className="col-md-12 empower_content">
                            <h3>How Aws Helps To Increase Your ROI</h3>
                            <div className="row">{/* <div class="col-md-5">
                <div class="empower_service_content">
                <h3>How Aws Helps To Increase Your ROI</h3>
                <p>Our End To End Aws Services Empower Companies To Build Highly Secure Performance Apps</p> 
                </div>
            </div> */}
                                <div className="col-md-12">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item empower_service_item blue"><a aria-controls="home" aria-selected="false" className="nav-link" data-toggle="tab" href="#home" id="home-tab" role="tab">Compute Solutions</a></li>
                                    <li className="nav-item empower_service_item green"><a aria-controls="profile" aria-selected="false" className="nav-link" data-toggle="tab" href="#profile" id="profile-tab" role="tab">Storage Solutions</a></li>
                                    <li className="nav-item empower_service_item skyblue"><a aria-controls="contact" aria-selected="false" className="nav-link" data-toggle="tab" href="#contact" id="contact-tab" role="tab">Database Solutions</a></li>
                                    <li className="nav-item empower_service_item orange"><a aria-controls="develop" aria-selected="false" className="nav-link" data-toggle="tab" href="#develop" id="develop-tab" role="tab">Network &amp; Content Delivery</a></li>
                                    <li className="nav-item empower_service_item red"><a aria-controls="design" aria-selected="false" className="nav-link" data-toggle="tab" href="#design" id="design-tab" role="tab">Amazon Analytics</a></li>
                                    <li className="nav-item empower_service_item pink"><a aria-controls="global" aria-selected="false" className="nav-link" data-toggle="tab" href="#global" id="global-tab" role="tab">Amazon Security and Compliance Services</a></li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div aria-labelledby="home-tab" className="tab-pane fade" id="home" role="tabpanel">
                                    <div className="row">
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon EC2</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon Elastic Container Service</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon Elastic Container Registry</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>AWS Batch</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>AWS Elastic Beanstalk</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>AWS Elastic Beanstalk</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>AWS Lambda</span></div>
                                    </div>
                                    </div>
                                    <div aria-labelledby="profile-tab" className="tab-pane fade" id="profile" role="tabpanel">
                                    <div className="row">
                                        <div className="col-md-4 cloud_Architecture"><span> Amazon S3</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon EBS</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon Elastic File System</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon Glacier</span></div>
                                    </div>
                                    </div>
                                    <div aria-labelledby="contact-tab" className="tab-pane fade" id="contact" role="tabpanel">
                                    <div className="row">
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon Aurora</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon RDS</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon DynamoDB</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon ElastiCache</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon Redshift</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon Neptune</span></div>
                                    </div>
                                    </div>
                                    <div aria-labelledby="develop-tab" className="tab-pane fade" id="develop" role="tabpanel">
                                    <div className="row">
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon VPC</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon Cloudfront</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon Route53</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon API Gateway</span></div>
                                    </div>
                                    </div>
                                    <div aria-labelledby="design-tab" className="tab-pane fade" id="design" role="tabpanel">
                                    <div className="row">
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon Athena</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon Kinesis</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon Elasticsearch Service</span></div>
                                    </div>
                                    </div>
                                    <div aria-labelledby="global-tab" className="tab-pane fade" id="global" role="tabpanel">
                                    <div className="row">
                                        <div className="col-md-4 cloud_Architecture"><span>Amazon IAM</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>AWS CloudHSM</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>AWS Shield</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>AWS KMS</span></div>
                                        <div className="col-md-4 cloud_Architecture"><span>AWS WAF</span></div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="container aws_development_content">
                        <h2>Why to choose AWS Partner Company for Business?</h2>
                        <p>Begin an AWS partner company, you can trust our developers having deep knowledge of AWS, Datage offers all types of aws services to its clients and forms a distinguished client base over the years. It has a team of AWS certified solutions architects, we support customers on the journey to the cloud and explains the benefits of AWS. As an AWS consulting partner it has a proven track record of successful client deployments on AWS and we optimize the value of IT investments by thinking creatively to solve specific problems from starting of the project for the future growth and flexibility.</p>
                        <p>If you are looking for AWS Partner Company to develop your business then your search ends here. Contact us now at <a href="mailto:hello@datage.in">hello@datage.in</a></p>
                        </div>
                        <div className="col-md-12 mp-services">
                            <h2>Related Services</h2>
                        <div className="container">
                            <div className="row">
                            <div className="col-md-4 black"><a href="https://datage.in/aws-consulting-services/">AWS Consulting Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/amazon-simple-storage-services/">Amazon S3 Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/amazon-notification-services/">Amazon Notification Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/server-maintenance-services/">Server Maintenance Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/docker-consulting/">Docker Consulting Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website Maintenance Services</a></div>
                            </div>
                        </div>
                        </div>
                        
                    </div>
                   
                    
                    </div>
                </div>
                </div>
                <div className="col-md-12 customer_reviews">
                <div className="container">
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ConsultingPartner
