import React from 'react'
import Callout from 'components/Callout'

function BootStrap( ) {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="seo_banner" style={{width: '100%'}}>
                        <div className="container">
                        <div className="service_banner row justify-content-center">
                            <div className="col-md-12">
                            <h1 className="heading">Bootstrap Web Development Services</h1>
                            <div className="content">
                                <p><strong>Bootstrap</strong> is a web development framework that was first developed by Twitter. It is free and open-source, and is very popular when it comes to designing websites and web applications. It offers HTML and CSS based design templates for typography, forms, buttons, navigation, and other interface components. One different fact about bootstrap is that unlike many frameworks, it is concerned only with front-end development. Despite this, it is the most popular HTML, CSS, and JS framework for developing responsive, mobile first projects on the web.</p>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="service_qualities bootstrap_content">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-12 content">
                                <p>Today Bootstrap is being used to develop millions of websites all around the world.</p>
                                <p>Bootstrap offers plenty of features. Some of these features include:</p>
                                <div className="row">
                                <div className="col-md-5">
                                    <ul>
                                    <li>Dominant images and image carousels</li>
                                    <li>Grid system</li>
                                    <li>Features for responsive design</li>
                                    </ul>
                                </div>
                                <div className="col-md-7">
                                    <ul>
                                    <li>Various design elements for navigation, tables, forms, and buttons</li>
                                    <li>Parallax scrolling (The backgrounds and the foregrounds move at different speeds, providing a 3D effect)</li>
                                    </ul>
                                </div>
                                </div>
                                <div className="various_designs">
                                <p>Besides all these features, it offers you the option of switching off the responsiveness feature to accommodate fixed, non-flexible functionality. You can easily get started with Bootstrap by downloading it from getbootstrap.com</p>
                                <p>Bootstrap framework is maintained by GitHub, and it offers three types of installations. You can select the most suitable one, depending on your skill level. The three installations are as follows:</p>
                                <h4 className="text-center">Pre-compiled version</h4>
                                <p>This version has everything pre-compiled and ready for immediate use. When we say that everything s pre-compiled, it means that all the CSS, JavaScript and fonts are ready to be used. This version is best for the users who do not know programming. This version has no source code files included for code level modification.</p>
                                <h4 className="text-center">Source code version</h4>
                                <p>This version also includes pre-compiled JavaScript and CSS, along with their respective source codes and documentation. However, it requires a Less Compiler. This version is suitable for web developers.</p>
                                <h4 className="text-center">Less to Sass version</h4>
                                <p>If you use Sass-only projects and rails or compass, you can download this version for your use.</p>
                                <h2 className="heading">Benefits of Bootstrap</h2>
                                <h6 className="text-center">Easy to Use</h6>
                                <p>One of the reasons why Bootstrap framework is so popular is its ease of usage. It is simple and easy. It provides a learning curve to starters, which no other framework does.</p>
                                <h6 className="text-center">Easy to Integrate</h6>
                                <p>One of the many uses of Bootstrap is that it can be integrated with almost all types of frameworks. This feature also gives you easy access into the frameworks and you can implement any of those pre-defined Bootstrap classes, without using mark-ups.</p>
                                <h6 className="text-center">Excellent speed</h6>
                                <p>Many developers worldwide use Bootstrap because the speed it offers is excellent and unmatchable. It offers speedy and swift development features. For the other frameworks you need to start coding from scratch, but Bootstrap offers you ready-made coding blocks, which you can utilize to build your website.</p>
                                <h6 className="text-center">Grid Style</h6>
                                <p>The layout of Bootstrap has 12 column grid styles, responsive, supporting offset and nested elements. As stated earlier, it also offers you the feature of getting rid of the responsive element and switching over to the fixed layout style.</p>
                                <h6 className="text-center">Base Style</h6>
                                <p>Its base style is packed with many features. These features include HTML elements for dealing with images, typography, and forms.</p>
                                <h6 className="text-center">Pre-styled components</h6>
                                <p>It comes with various pre-styled components such as dropdowns, alerts, navigation bars etc.</p>
                                <h6 className="text-center">Support and Documentation</h6>
                                <p>It comes with excellent support and documentation to help beginners.</p>
                                <h6 className="text-center">Customizable</h6>
                                <p>Bootstrap is a customizable framework. It can be customized as and when required, depending upon the requirements of your project.</p>
                                <h6 className="text-center">Responsive</h6>
                                <p>Bootstrap is responsive, which means that it provides you with a fluid grid layout. The fluid grid layout allows you to organize your website into the dimensions of the mobile device.</p>
                                <h6 className="text-center">Consistent</h6>
                                <p>It provides a well-defined consistency across the app or website system.</p>
                                <h6 className="text-center">Frequent updates</h6>
                                <p>Bootstrap gets updated more frequently than the other frameworks. All the updates of Bootstrap are tailor-made, such as the ones that support cross-browser compatibility along with cross-device compatibility.</p>
                                <p>At Datage we can develop your website using Bootstrap. Contact us at <a href="mailto:hello@datage.in">hello@datage.in</a> to know more about it.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        
                        <div className="contact_container row justify-content-center">
                        <div className="col-md-12 col-lg-12">
                            <div className="contact_block row marl">
                            <div className="col-md-7 col-xs-12">
                            <Callout />
                            </div>
                            <div className="col-md-5 col-xs-12 col-sm-12">
                                <div className="contact_content"><span className="jost">Bootstrap Web Development USA</span>
                                <p>Bootstrap has made front end web development faster and easier. It can easily be used for devices of all shapes and projects of all sizes.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                    </div>
                    </div>
                </div>
                <div className="col-md-12 customer_reviews">
                    <div className="container">
                    </div>
                </div>
                </div>
            </div>
        </div>   
    </div>
    )
}

export default BootStrap
