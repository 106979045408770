import React from 'react'
import Callout from 'components/Callout'

function ServerMaintenance( ) {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="service_banner row justify-content-center">
                        <div className="col-md-8">&nbsp;
                        <div className="content">
                            <h1 align="center">SERVER MAINTENANCE SERVICES</h1>
                        </div>
                        <div className="content">
                            <p style={{fontSize: 24, lineHeight: 33}}>Your server requires regular maintenance and tuning to perform at its best. At Datage, we monitor, protect and tune your servers. You can focus more on the product and let us take care of the supporting hardware &amp; software.</p>
                        </div>
                        </div>
                    </div>
                    <div className="row react_about_row server_row">{/*     <div class="row text-center"><span class="heading"><span>Our Process</span></span></div>
            */}
                        <div className="container">
                        <div className="row react_row">
                            <div className="order-3 order-md-9 col-sm-4 col-xs-12 tb_block tb_img right"><img alt="Server Monitoring" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/server_monit.png" /></div>
                            <div className="order-9 order-md-3 col-sm-8 col-xs-12 tb_block_b right">
                            <div className="desc"><strong>Server Monitoring</strong>
                                <p>We use top notch softwares like Zabbix and NagiOs to monitor your server 24 X 7. Whenever system reaches a critical state or if a service is down, our notification system in place, will notify us and our geeky engineers get into action to resolve it.</p>
                            </div>
                            </div>
                        </div>
                        <div className="row react_row">
                            <div className="col-md-3 col-sm-4 col-xs-12 tb_block tb_img left"><img alt="Server Update Software System" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/srever_updaterea.png" /></div>
                            <div className="col-md-9 col-sm-8 col-xs-12 tb_block_b left">
                            <div className="desc"><strong>Server Update Software System</strong>
                                <p>Keeping your system up-to-date will protect your application and server from many threats and attacks, we keep watch on system and security updates for OS and Applications, make a choice to install them, if it is safe and doesn’t break your application</p>
                            </div>
                            </div>
                        </div>
                        <div className="row react_row">
                            <div className="order-3 order-md-9 col-sm-4 col-xs-12 tb_block tb_img right"><img alt="Server Backup" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/server_backup.png" /></div>
                            <div className="order-9 order-md-3 col-sm-8 col-xs-12 tb_block_b right">
                            <div className="desc"><strong>Server Backup</strong>
                                <p>Having an Disaster recovery plan is as important as running your server or applications. Rest assured that our team always takes Point-in-Time Backups of server, application and database. In case of any catastrophic events, we can immediately pull from our backups and be back online in no-time.</p>
                            </div>
                            </div>
                        </div>
                        <div className="row react_row">
                            <div className="col-md-3 col-sm-4 col-xs-12 tb_block tb_img left"><img alt="firewall Protection" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/server_firewall.png" /></div>
                            <div className="col-md-9 col-sm-8 col-xs-12 tb_block_b left">
                            <div className="desc"><strong>Firewall Protection</strong>
                                <p>Having complete control over traffic is one of the important aspect of owning a server, Datage will harden your server security to heights that you can have control of where data should flow into server and who should access your data.</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-12 mp-services"><span>Related Services</span>
                        <div className="container">
                        <div className="row">
                            <div className="col-md-4 black"><a href="https://datage.in/aws-consulting-services/">AWS Consulting Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/amazon-simple-storage-services/">Amazon S3 Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/amazon-notification-services/">Amazon SES Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/aws-consulting-partner/">AWS Consulting Partner</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/docker-consulting/">Docker Consulting Services</a></div>
                            <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website Maintenance Services</a></div>
                        </div>
                        </div>
                    </div>
                    
                    <div className="contact_container row justify-content-center">
                        <div className="col-md-12 col-lg-10">
                        <div className="contact_block row marl">
                            <div className="col-md-7 col-xs-12">
                            <Callout />
                            </div>
                            <div className="col-md-5 col-xs-12 col-sm-12">
                            <div className="contact_content"><span className="jost">Server Maintenance Services.</span>
                                <p>We at Datage, maintain your servers at its best, so you never have to worry about it security and performance.</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
                <div className="col-md-12 customer_reviews">
                <div className="container">
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ServerMaintenance
