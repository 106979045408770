import React from 'react'
import { Link } from 'react-router-dom';

function FooterLinks() {
    return (
        <div>
         <div className="footer_links row marl no-gutters">
            <div className="col-md-3">
                <ul>
                <li>Services</li>
                <li><Link to="/reactjs-development-services/" >ReactJs Development</Link></li>
                <li><Link to="/angularjs-development-services/" >AngularJs Development</Link></li>
                <li><Link to="/#" >GraphQL Development</Link></li>
                <li><Link to="/django-development-services/" >Django Development</Link></li>
                <li><Link to="/python-development-services/" >Python Development</Link></li>
                <li><Link to="/postgresql-administration-and-management/" >PostgreSQL Administration</Link></li>
                <li><Link to="/mongodb-development-services/" >MongoDB Development</Link></li>
                </ul>
            </div>
            <div className="col-md-3">
                <ul>
                <li><Link to="/e-learning-software-development-services/" >E-Learning Software Development</Link></li>
                <li><Link to="/django-oscar-development-services/" >Django Oscar Development</Link></li>
                <li><Link to="/ecommerce-web-development-services/" >Ecommerce Web Development</Link></li>
                <li><Link to="/ecommerce-web-development-services/" >Ecommerce ERP Implementation</Link></li>
                <li><Link to="/#" >Figma Design and Prototyping</Link></li>
                <li><Link to="/bootstrap-web-development/" >Bootstrap Web Development</Link></li>
                <li><Link to="/responsive-web-design/" >Responsive Web Design</Link></li>
                <li><Link to="/aws-consulting-services/" >Aws Consulting Services</Link></li>
                </ul>
            </div>
            <div className="col-md-3">
                <ul>
                <li><Link to="/amazon-simple-storage-services/" >Simple Storage Services</Link></li>
                <li><Link to="/amazon-notification-services/" >Simple Notification Services</Link></li>
                <li><Link to="/aws-consulting-partner/" >AWS Consulting Partner</Link></li>
                <li><Link to="/react-native-development-services/" >React Native Development Services</Link></li>
                <li><Link to="/android-app-development-services/" >Android Application Development</Link></li>
                <li><Link to="/#" >NodeJS/Express Development</Link></li>
                <li><Link to="#" >ElasticSearch Development</Link></li>
                <li><Link to="/salesforce/hire-developer/" >Firebase Development</Link></li>
                </ul>
            </div>
            <div className="col-md-3">
                <ul className="company_cloud">
                <li>About Company</li>
                <li><Link to="/our-tech-stack/" >Tech</Link></li>
                <li><Link to="/our-portfolio/" >Portfolio</Link></li>
                <li><Link to="/contact-us/" >Careers</Link></li>
                <li><Link to="/contact-us/" >Contact Us</Link></li>
                <li><Link to="/#" >Sitemap</Link></li>
                <li><Link to="/contact-us/" >Request For A Demo</Link></li>
                </ul>
            </div>
        </div>
    </div>
    )
}

export default FooterLinks;
