import React from 'react'

function CloudSection( ) {
    return (
        <div>
            <section className="cloud_solutions custom_skills">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                        <div className="heading amazon_consulting">AWS Cloud Services</div>
                        <div className="description_block">
                        <p>Our Certified developers and solution architects team, build awesome web &amp; mobile applications. We mainly focus on security, performance, scalability to give maximum user satisfaction and ROI.</p>
                        <p>Datage offers all types of AWS cloud services to its clients. we could deliver what application needs to meet its goals with our proven methodology. Our team develops and manages solutions at scale. Whether it's hundreds of users or thousands of users, not to worry our certified cloud architects have your back to scale and load balance your applications.</p>
                        <ul>
                            <li>Infrastructure Planning and Management</li>
                            <li>Security and High Availability</li>
                            <li>load balancing and auto scaling</li>
                            <li>DevOps</li>
                        </ul>
                        </div>
                        <div className="read_more_app">
                        <a href="/aws-consulting-services/" target="_blank"><strong>Read More</strong></a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img loading="lazy" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/new-images/aws_service_home.png" width="100%" alt="amazon_services" />
                    </div>
                    </div>
                </div>
                </section>
        </div>
    )
}

export default CloudSection;
