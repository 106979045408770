import React from 'react'

function PortfolioPage( ) {
    return (
        <div>
         <div className="scroller">
          <div className="scroller-inner">
            <div className="codrops-top clearfix" />
            <div className="content clearfix">
            <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                    <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    </div>
                    <div className="modal-body">
                    <div className="text content-desc">
                        <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div id="container_wrapper">
                <div className="row justify-content-center">
                <div className="clearfix" />
                {/* container wrapper */}
                <div className="row portfolio justify-content-center">
                    <div className="portfolio_banner">
                    <div className="container text-center">
                        <h1>PORTFOLIO</h1>
                    </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img loading="lazy" alt="SellerApp" src="https://cdn.sellerapp.com/img/sellerapp-amazon-logo.svg" />
                        </span></div>
                        <div className="project_name">
                        <h3>SellerApp</h3>
                        <h5><a href="https://sellerapp.com" rel="nofollow" target="_blank">https://www.sellerapp.com/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>Seller App's Smart-Data helps for Amazon Growth, Calculate Profits, PPC Campaigns, In-depth Keywords & Product Research, Analyze Competition and more.</p>
                    </div>
                    </div>

                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img width="180" loading="lazy" alt="Riskcovry" src="https://riskcovry.com/wp-content/uploads/2019/11/rcom.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>Riskcovry</h3>
                        <h5><a href="https://riskcovry.com" rel="nofollow" target="_blank">https://www.riskcovry.com/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>1 platform to centralise all your needs around insurance product, tech, and compliance. “Insurance-in-a-box” model offers a one-stop-shop platform to cater to any business's digital insurance needs.</p>
                    </div>
                    </div>

                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img width="180" loading="lazy" alt="Riskcovry" src="http://www.activdoctorsonline.com/wp-content/themes/responsive_theme/images/ActivDoct_logo.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>ActivDoctorsOnline</h3>
                        <h5><a href="http://www.activdoctorsonline.com/" rel="nofollow" target="_blank">http://www.activdoctorsonline.com/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>Activ Doctors Online is one of the world’s first companies to provide a complete suite of HIPPAA compliant telemedicine solutions from a single integrated platform. Through Online Personal Health Records and our online educational tools, ADO members are more prepared to manage their entire family’s health care more efficiently. </p>
                    </div>
                    </div>

                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img loading="lazy" alt="ForexRates" src="https://d28k6hocvoxiuc.cloudfront.net/project/attachments/242e6a71c6fb17d73d21f49fdd38e6e4/faa80.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>ForexRates</h3>
                        <h5><a href="https://play.google.com/store/apps/details?id=com.forexrates" rel="nofollow" target="_blank">https://play.google.com/store/apps/details?id=com.forexrates</a></h5>
                        </div>
                    </div>                    
                    <div className="col-md-9 portfolio_projects_content">
                        <p>Forex rates is a native mobile application developed for Android and IOS. This application is used to get the foreign exchange rates and historical exchange rates with ease.</p>
                    </div>
                    </div>
                    
                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img loading="lazy" alt="Creditable" src="https://d28k6hocvoxiuc.cloudfront.net/project/attachments/5de23af70a35e93b1dfb08df008a2fa0/creditable_portfolio.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>Creditable</h3>
                        <h5><a href="https://creditable.co/" rel="nofollow" target="_blank">https://creditable.co/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>Creditable is loan &amp; savings management system for employees. Creditable is partnered 3rd party banks(ubank, DBS) and provides loans to employees in a organisation. Creditable has unique tools that help measure real employee outcomes, measure your employees savings &amp; loan accessibilty against increased productivity, employee turnover &amp; overall happiness at work.&nbsp;Creditable is designed to save you time whilst also meeting your compliance and risk burdens. The dashboard makes it easy to review, analyse and comfortably manage an audit.</p>
                        <p>&nbsp;</p>
                    </div>
                    </div>

                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img width="180"  loading="lazy" alt="Creditable" src="https://zdoc.in/assets/img/logo.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>Zdoc</h3>
                        <h5><a href="https://zdoc.in/" rel="nofollow" target="_blank">https://zdoc.in/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>Zdoc offers the ultimate all-in-one practice growth platform to attract more patients and grow your practice in your neighborhood and online.</p>
                    </div>
                    </div>

                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img width="180"  loading="lazy" alt="Creditable" src="https://www.activ4pets.com/img/activ4pets_colored-logo.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>Activ4Pets</h3>
                        <h5><a href="https://www.activ4pets.com/" rel="nofollow" target="_blank">https://www.activ4pets.com/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>Activ4Pets is the first company of its kind, enabling pet owners to access their animal’s complete health history and even consult with their veterinarian online – all via an easy to use web-based platform.</p>
                    </div>
                    </div>
                    
                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img loading="lazy" alt="Radiostring" src="https://d28k6hocvoxiuc.cloudfront.net/project/attachments/86beae495ab39595eb380faf5686e922/radiostring.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>Radiostring</h3>
                        <h5><a href="https://radiostring.com/" rel="nofollow" target="_blank">https://radiostring.com/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>Radiostring.com is the best web radio streaming service providing a huge amount of stations across worldwide. It was launched in 2017 and it can be accessed from Desktop, Tablets, Mobile. The application will be available on all devices like Android,iPhone, Windows mobiles.</p>
                    </div>
                    </div>
                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img loading="lazy" alt="ParentOrbit" src="https://d28k6hocvoxiuc.cloudfront.net/project/attachments/b5fcaab76d5146c94f7e4c743b5b308b/parentorbit.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>ParentOrbit</h3>
                        <h5><a href="https://www.parentorbit.com/" rel="nofollow" target="_blank">https://www.parentorbit.com/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>ParentOrbit is a Class / School Management Software. This is a platform for individuals to businesses allows to create classes of any size and accept online and offline Registration. With ParentOrbit the individuals or businesses can manage student enrollment &amp; billing. This platform has the feature to manage onetime or recurring billing, accept eCheck/ACH or credit card payments.</p>
                    </div>
                    </div>
                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img loading="lazy" alt="Wonderland resorts" src="https://d28k6hocvoxiuc.cloudfront.net/project/attachments/8fcbf0d9d500870765cf991acf6ca110/wonderland_logo.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>Wonderland resorts</h3>
                        <h5><a href="https://wonderlandresorts.com/" rel="nofollow" target="_blank">https://wonderlandresorts.com/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>Wonderland Aspen in plans to build an application specializing in internet related services and products in hotel, lodging and meta search fields. We deployed a haystack plus elastic-search for meta search, a comprehensive web framework to build hotel management web application with calendar integrated to the dashboard of the application for bookings and timings. SEO implementation for better google search ranking.</p>
                    </div>
                    </div>
                    
                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img loading="lazy" alt="Techond" src="https://d28k6hocvoxiuc.cloudfront.net/project/attachments/a374efe652c1b6e498de930b432c01d9/techond.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>Techond</h3>
                        <h5><a href="http://techond.com/" rel="nofollow" target="_blank">http://techond.com/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>Techond is an online JOB portal. This is common platform Employers, Consutants and JobSeekers. Tochond is developed with some unique features for the JobSeekers. Techond allows the JobSeekers to enroll for the Training and Placement program. Employer will create a Job and assign to a consultant and consultant will shortlist the JobSeeker based on the Job's required skills and shortlist the Jobseekers for that Job. This flow of recruitement will deliver quality of resources to the Employers and helps the JobSeekers in improving their skills.</p>
                    </div>
                    </div>
                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img loading="lazy" alt="Boughdigital" src="https://d28k6hocvoxiuc.cloudfront.net/project/attachments/8fc894159db3205c265e3dcea47a5fb2/bought.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>Boughdigital</h3>
                        <h5><a href="https://www.boughdigital.com" rel="nofollow" target="_blank">https://www.boughdigital.com</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>Bough Digital is a professional full-service digital marketing company based in the UK which aims to deliver a first-class, full scale service to a variety of clients.&nbsp;BoughDigital's team has a wealth of digital marketing experience that can help websites gain a competitive but fair advantage in their specific market sectors.&nbsp;The Bough is the largest and most important branch of a tree and its team is bringing that school of thought to the digital world; it believes search engine optimisation is the most important and largest branch of any digital marketing strategy but understand how every marketing tactic working together within a holistic approach is the best way to achieve success.</p>
                    </div>
                    </div>
                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img loading="lazy" alt="ProMusicTutor" src="https://d28k6hocvoxiuc.cloudfront.net/project/attachments/413c5c8e7957eff81164482616c4b991/pro_music.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>ProMusicTutor</h3>
                        <h5><a href="http://www.promusictutor.com/" rel="nofollow" target="_blank">http://www.promusictutor.com/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>JEM MUSIC INT’L LTD were looking for an online music tutorial platform with “learn to play” model that would rely on sessions with great lessons for novice and advanced players alike. The need was to host the best combination of traditional and modern music learning modules with same level of accountability and reinforcement as face-to-face lessons. A need of commission based custom affiliate link online training with transparency and security across all the platforms. To seamlessly deliver across all possible platforms and devices, Datage created Audio visual website that served as business model name ProMusicTutor adhering to all the challenges such as optimizing audio video digital container format and abandoned cart follow up.</p>
                    </div>
                    </div>
                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img loading="lazy" alt="TheCrimsonBride" src="https://d28k6hocvoxiuc.cloudfront.net/project/attachments/aff8079065964766a4fadda8f5e47e93/crim.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>TheCrimsonBride</h3>
                        <h5><a href="https://thecrimsonbride.com/" rel="nofollow" target="_blank">https://thecrimsonbride.com/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>The crimsonbride is a platform where the brides can find &amp; contact professionals. It is easy for the brides to register to the website and find professionals and hire them. The platform allows the professionals like Photographers, Costume designers to register themselves and add their skills to their profiles such that they could be easily visible to users.</p>
                    </div>
                    </div>
                    <div className="row projects_list">
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img loading="lazy" alt="goshoppi" src="https://d28k6hocvoxiuc.cloudfront.net/project/attachments/7a13d2c4c949957e4a03fa534aeece5a/shoppfy.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>goshoppi</h3>
                        <h5><a href="http://www.goshoppi.com/" rel="nofollow" target="_blank">http://www.goshoppi.com/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>Pull-up stock database from stores in minimal time possible. Search of such databases must load in lowest time. Less than 50 milliseconds load time for search and less than 5 minutes to fetch stock details from store and update in their database.</p>
                    </div>
                    </div>
                    <div className="row projects_list" style={{marginBottom: '3rem !important'}}>
                    <div className="col-md-3 portfolio_projects">
                        <div className="project_logo"><span>
                            <img loading="lazy" alt="Artbnkr" src="https://d28k6hocvoxiuc.cloudfront.net/project/attachments/330d31ab5b17810e6b8f58f29877ad50/ab.png" />
                        </span></div>
                        <div className="project_name">
                        <h3>Artbnkr</h3>
                        <h5><a href="https://artbnkr.com/" rel="nofollow" target="_blank">https://artbnkr.com/</a></h5>
                        </div>
                    </div>
                    <div className="col-md-9 portfolio_projects_content">
                        <p>Artbnkr is a platform developed for Deteast. Artbnkr helped Deteast to manage their VFX project in a smart way. On this platform admin can manage projects. Upload media related&nbsp; to that project. Review and Approve the media related to that project. This made life easier for Deteast in managing so many of their prestigious in a quick and easy way.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
    )
}

export default PortfolioPage;
