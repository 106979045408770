import React from 'react';
import TextSection from './TextSection';
import TechSection from './TechSection';
import DesignSection from './DesignSection';
import DevelopmentSection from './DevelopmentSection';
import DesignUiSection from './DesignUiSection';
import CloudSection from './CloudSection';
import MobileSection from './MobileSection';
import WhyUs from './WhyUs';


function Sections( ) {
    return (
        <div>
            <TechSection /> 
            <DevelopmentSection/>
            <DesignUiSection/>
            <CloudSection />
            <MobileSection />
            <WhyUs />
        </div>
    )
}

export default Sections
