import React from 'react'
import Callout from 'components/Callout'

function Angular() {
    return (
    <div>
        <div className="scroller">
            <div className="scroller-inner">
                <div className="codrops-top clearfix" />
                <div className="content clearfix">
                <div className="modal fade" id="service_modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Need Help?</h5>
                        <button type="button" className="close" id="service_contact_close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <div className="text content-desc">
                            <div className="service_modal_content">Need any Help in your Project? Talk to our Experts regarding your Queries requirement <span className="talkshop_button"><a href="/contact-us/">Let's Talk</a></span></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="container_wrapper">
                    <div className="row justify-content-center">
                    <div className="clearfix" />
                    <div className="service_page_container">
                        <div className="row about_angularjs row justify-content-center">
                        <div className="angular_process">&nbsp;</div>
                        <div className="col-md-12 angular_feactures">
                            <div className="container">
                            <h1 className="heading text-center">Angular Development Company</h1>
                            <p>Datage is the leading Angular development company and provides offshore Angular development services. We have a dedicated Angular development team to develop fully functional, secure and easy to maintain web and mobile application. We use latest tools and technologies to provide world-class AngularJs development services to clients.</p>
                            <p>As a leading Angular development company, Datage provides end-to-end solutions to its clients to provide the benefits of Angulars.</p>
                            </div>
                        </div>
                        <div className="row angular_service">
                            <div className="container">
                            <h2 className="heading text-center">Web And Mobile Angular Development Services.</h2>
                            </div>
                            <div className="container">
                            <div className="row">
                                <div className="col-md-12 angularjs_tools">
                                <div className="row">
                                    <div className="col-md-6">
                                    <p>Datage has 5+ years of experience in Angular development services and provided end-to-end solutions to it's clients</p>
                                    <p>Angular is an open source web application JavaScript framework maintained by Google. It was designed to resolve the challenges which are encountered in developing single page applications. It aims to simplify the testing and developing of such applications.</p>
                                    </div>
                                    <div className="col-md-6 angular_devlopment"><img alt="Angular Development Services" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/angular_application-development.png" /></div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="container">
                            <div className="row">
                                <div className="col-md-12 angularjs_tools">
                                <div className="row">
                                    <h2 className="heading text-center hundred_percentage">Why Use Angular For Application Development</h2>
                                    <div className="col-md-6 angular_application"><img alt="Angular Features and Benefits" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/angular_features_benefits.png" /></div>
                                    <div className="col-md-6">
                                    <p>HTML is great for declaring static documents but it is not so good for declaring dynamic views on web applications. Angular allows you to extend the HTML vocab for your application which ensures that the resulting environment is quite easy to develop and every feature in it can be modified or replaced to meet the unique development needs for your application. Angular allows you to extend the HTML vocab for your application.</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="container"><span>Why Hire Angular Developers From Datage?</span></div>
                        <div className="container">
                            <p>Datage has expertise Angular developers to hire and to develop your customized application. Here are some key benefits to hire Angular developers for building a successful angular application.</p>
                            <div className="row angularjs_hireing">
                            <div className="col-md-3">
                                <div className="angular_expertise"><img alt="Experts in Custom Angular Development" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/angular-expertise.png" /></div>
                                <p>Specialization in Custom Development</p>
                            </div>
                            <div className="col-md-3">
                                <div className="angular_expertise"><img alt="Cost Efficient Solutions" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/angular-budget.png" /></div>
                                <p>Cost Effective Solutions</p>
                            </div>
                            <div className="col-md-3">
                                <div className="angular_expertise"><img alt="High Experience Developers" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/angular-development.png" /></div>
                                <p>Highest Level of Expertise</p>
                            </div>
                            <div className="col-md-3">
                                <div className="angular_expertise"><img alt="Angular Support and Maintenance" src="https://d28k6hocvoxiuc.cloudfront.net/site/images/angular-support .png" /></div>
                                <p>Technical Support And Maintenance</p>
                            </div>
                            </div>
                            <div className="col-md-12 mp-services"><span>Related Services</span>
                            <div className="row">
                                <div className="col-md-4 black"><a href="https://datage.in/django-development-services/">Django Development</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/python-development-services/">Python Development</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/reactjs-development-services/">ReactJs Development</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/website-maintenance-services/">Website Maintenance Services</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/e-learning-software-development-services/">E-Learning Software Development</a></div>
                                <div className="col-md-4 black"><a href="https://datage.in/react-native-development-services/">React Native Development </a></div>
                            </div>
                            </div>
                        </div>
                        &nbsp;
                        <div className="container">
                            
                            &nbsp;
                            <div className="contact_container row justify-content-center no-gutters">
                            <div className="col-md-12 col-lg-12">
                                <div className="contact_block row marl">
                                <div className="col-md-7 col-xs-12">
                                <Callout />
                                </div>
                                <div className="col-md-5 col-xs-12 col-sm-12">
                                    <div className="contact_content"><span className="heading">Angular Development</span>
                                    <p>Angular makes easy to develop web and mobile applications. With angular, we can create Single page applications (SPA) and website menu in an instant. Get experienced Angular developers when you need.</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        {/*/ container wrapper */}</div>
                    
                    </div>
                </div>
                </div>
                <div className="col-md-12 customer_reviews">
                <div className="container">
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Angular
